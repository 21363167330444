import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import ModalEquipamentoPreenchidos from '../../../components/Templates/ModalPreenchidos';
import TableTeamplate from '../../../components/Templates/Table/TableTeamplate';


export const Setores = props => {

    const headers = [{
        label: "Área",
        filtravel: true,
        name: 'area_descricao'
    }, {
        label: "Descricao",
        filtravel: true,
        name: 'descricao'
    },
    {
        label: "Localização",
        filtravel: true,
        name: 'localizacao'
    },
    { label: "Ações" }
    ];

    const [modal, setModal] = useState();
    const [setorId, setSetorId] = useState();

    const modalToggle = (id) => {
        setSetorId(id);
        setModal(p => !p);
    }

    const body = [
        a => a.area ? a.area.descricao : '',
        'descricao',
        'localizacao',
        (cadastro) =>
            <div className='d-flex align-items-center'>
                <Link to={`/setores/editar/${cadastro.uuid}`}>
                    <i className="cui-note icons font-2xl "></i>
                </Link>
                <Button color="link" className="pl-2 py-0" onClick={() => modalToggle(cadastro.id)}>
                    <i className="cui-layers icons font-2xl"></i>
                </Button>
            </div>
    ]

    return <>

        <TableTeamplate
            showFiltro={true}
            end_point={'/setores'}
            entidade='setor'
            acoesPreDefinidas={["GERAR_QR_CODE"]}
            titulo='Setores'
            headers={headers}
            body={body}
            rota='setores' />
        {setorId ? <ModalEquipamentoPreenchidos
            filtros={{ setorId: setorId }} isOpen={modal} toggle={() => modalToggle()} /> : null}

    </>


};