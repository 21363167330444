import React, { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Modal, ModalBody, ModalHeader, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import TableBody from './Table/TableBody';
import TableHeaders from './Table/TableHeaders';
import { Table } from '../ui/Table';
import { useLazyQuery, gql, useQuery } from '@apollo/client';
import Loading from '../../lib/Loading';
import { formatToBrasilTimeZone } from '../../lib/DateUtil';

const ModalPreenchidos = ({ filtros, isOpen, toggle }) => {
    const query = gql`
    query(
        $equipamentoId: ID,
        $setorId: ID,
        $first: Int!,
        $page:Int!,
        $funcionarioId:ID,
        $produtorId:ID){
        preenchidos(
            first: $first,
            page: $page,
            equipamentoId: 
            $equipamentoId, 
            setorId: $setorId, 
            funcionarioId:$funcionarioId,
            produtorId: $produtorId ){
            data{
                uuid,
                vistoria_data
                monitor{
                    nome
                },
                esqueleto{
                    uuid,
                    nome
                }
            },
            paginatorInfo{
                lastPage
            }
            
        }   
    }`

    const [currentPage, setCurrentPage] = useState(1);


    const { loading, error, data, fetchMore } = useQuery(query, {
        variables: {
            page: currentPage,
            first: 15,
            ...filtros
        }
    })

    useEffect(() => {
        if (fetchMore) {
            fetchMore({ variables: { page: currentPage } })
        }
    }, [currentPage])

    return (
        <Modal
            unmountOnClose
            style={{ maxWidth: '80%', width: '100%' }}
            centered
            scrollable
            isOpen={isOpen}
            toggle={() => toggle()}>
            {isOpen ?
                <>
                    <ModalHeader toggle={() => toggle()}>Planilhas</ModalHeader>
                    <ModalBody>
                        {loading ? <Loading /> :
                            <div>
                                <Table>
                                    <TableHeaders
                                        headers={[
                                            { label: 'Planilha' },
                                            { label: 'Monitor' },
                                            { label: 'Data' },
                                            { label: 'Links' }
                                        ]} />

                                    <TableBody
                                        cadastros={data.preenchidos.data}
                                        body={[
                                            p => p.esqueleto.nome,
                                            p => p.monitor.nome,
                                            p => formatToBrasilTimeZone(p.vistoria_data),
                                            p => <Link to={`/vistorias/${p.esqueleto.uuid}/realizada/${p.uuid}`}>
                                                <i className="fa fa-external-link font-2xl"></i>
                                            </Link>
                                        ]} />
                                </Table>
                                <Pagination className='d-flex justify-content-center'>
                                    <PaginationItem disabled={currentPage == 1}>
                                        <PaginationLink previous tag="button" onClick={() => setCurrentPage(c => c - 1)} />
                                    </PaginationItem >

                                    <PaginationItem active>
                                        <PaginationLink tag="button">
                                            {currentPage}
                                        </PaginationLink>
                                    </PaginationItem>
                                    <PaginationItem disabled={currentPage === data.preenchidos.paginatorInfo.lastPage}>
                                        <PaginationLink next tag="button" onClick={() => setCurrentPage(c => c + 1)} />
                                    </PaginationItem>
                                </Pagination>
                            </div>
                        }
                    </ModalBody>
                </>
                : null}
        </Modal>
    )
}
export default ModalPreenchidos