import React from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { Card, CardHeader, CardBody, Row, Badge, Col, Button, CardColumns } from 'reactstrap';
import moment from 'moment';
import { useState } from 'react';
import { useEffect } from 'react';
import ApiV2 from '../../../lib/ApiV2';
import { toast } from 'react-toastify';
import Api from '../../../lib/Api';
import DatePicker from '../../../components/ui/DatePicker';
import TableHeaders from '../../../components/Templates/Table/TableHeaders';
import { Table } from '../../../components/ui/Table';
import TableBody from '../../../components/Templates/Table/TableBody';
import TableFooter from '../../../components/Templates/Table/TableFooter';
import { vinculos } from '../../../utils';

const Vistorias = ({ esqueletoUuid }) => {

    const [preenchidos, setPreenchidos] = useState([]);

    const [esqueleto, setEsqueleto] = useState({});

    const [isLoading, setIsloading] = useState(true);

    const [filtros, setFiltros] = useState();

    const [filtroData, setFiltroData] = useState({ ini: null, fim: null });

    useEffect(() => {

        (async _ => {
            setIsloading(true);
            const [preenchidosData, error] = await ApiV2.fetchPreenchidos(esqueletoUuid, filtroData, filtros);
            const [esqueletoData, error2] = await Api.fetchEsqueleto(esqueletoUuid);

            if (!error && !error2) {
                setPreenchidos(preenchidosData);
                setEsqueleto(esqueletoData);
            } else {
                toast.error("Erro ao buscar dados");
            }

            setIsloading(false);
        })();

    }, [filtroData, filtros])

    let ultimaVistoria = '';
    let zebra = true;

    const checkBackGroundColor = vistoria_data => {

        const data = moment(vistoria_data).format('YYYY-MM-DD');
        if (data === ultimaVistoria) {
            return zebra ? 'rgba(0,0,0,.05)' : '#FFF';
        } else {
            ultimaVistoria = data;
            zebra = !zebra;
            return zebra ? 'rgba(0,0,0,.05)' : '#FFF';
        }
    }


    const getNomeRelacao = (vinculo) => {
        return vinculos[vinculo];
    }


    const getRelacao = (vinculo, preenchido) => {

        if (!preenchido[vinculo]) {
            return '';
        }

        if (preenchido[vinculo].nome) {
            return preenchido[vinculo].nome;
        }

        if (preenchido[vinculo].descricao) {
            return preenchido[vinculo].descricao;
        }

        return '';

    }


    let headers = [{
        label: "Monitor",
        filtravel: true,
        name: 'monitor'
    },
    {
        label: "Registro",
    },
    {
        label: "Status",
    }];


    let body = [
        (a) => a.monitor.nome,
        (a) => moment(a.vistoria_data).format('DD/MM/YYYY HH:mm:ss'),
        (a) => {
            if (a.contagem_nao_conformidades > 0)
                return <Badge color={'danger'}>
                    {a.contagem_nao_conformidades + ' Não Conformidades'}
                </Badge>
            return <Badge color={'success'}>
                Conforme
            </Badge>
        }
    ]

    if (esqueleto.vinculo) {

        const headerRelacao = {
            label: getNomeRelacao(esqueleto.vinculo),
            filtravel: true,
            name: esqueleto.vinculo
        };
        headers = [
            headerRelacao,
            ...headers];

        body = [
            (a) => getRelacao(esqueleto.vinculo, a),
            ...body,
        ]

        if (esqueleto.vinculo === "produtor") {
            headers.push({
                label: "Classificação",
                filtravel: false
            });

            body.push(
                (a) => a.classificacao_pqfl
            )
        }
    }

    headers.push({
        label: "Ações"
    });

    body.push(
        (cadastro) => <Link to={`/vistorias/${esqueletoUuid}/realizada/${cadastro.uuid}`}>
            <i className={'fa fa-external-link font-2xl'}></i>
        </Link>
    )

    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(s => !s);
    return (
        <>
            <Card>
                <CardHeader>
                    <div className="d-flex justify-content-between">
                        <div>
                            <h2 style={{ fontWeight: 300 }}> {esqueleto.nome}
                            </h2>
                        </div>
                        <div className='d-flex align-items-center'>
                            <div className='text-muted' style={{ minWidth: '250px' }}>
                                {!!filtroData.ini &&
                                    <Row>
                                        <Col sm={3}>Início: </Col>
                                        <Col sm={9}>{moment(filtroData.ini).format("DD/MM/YYYY HH:mm:ss")}</Col>
                                    </Row>
                                }

                                {!!filtroData.fim &&
                                    <Row>
                                        <Col sm={3}>Fim: </Col>
                                        <Col sm={9}>{moment(filtroData.fim).format("DD/MM/YYYY HH:mm:ss")}</Col>
                                    </Row>
                                }
                            </div>
                            <div style={{ position: "relative" }}>
                                <Button onClick={toggle} color="secondary">
                                    <i className="cui-calendar icons font-2xl"></i>
                                </Button>
                                <DatePicker
                                    isOpen={isOpen}
                                    inicialValues={filtroData}
                                    onSelect={(filtro) => { setFiltroData(filtro); toggle() }} />
                            </div >
                        </div>
                    </div>
                </CardHeader>
                <CardBody>
                    <Table >
                        <TableHeaders
                            isLoading={isLoading}
                            headers={headers}
                            onFiltroChange={filtros => setFiltros(filtros)}
                            showFiltro />   

                        <TableBody
                            rowStyle={(h) => ({ backgroundColor: `${checkBackGroundColor(h.vistoria_data)}` })}
                            showCheckbox={false}
                            body={body}
                            cadastros={preenchidos}
                            isLoading={isLoading}
                        />
                        <TableFooter cadastros={preenchidos} />
                    </Table>
                </CardBody>
            </Card>
        </>
    )
};
export default Vistorias