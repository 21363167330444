import React from 'react';
import { v4 } from 'uuid';
import FormUI from '../../../components/Templates/Form';

export const ProdutoresForm = props => {

    const validate = (values) => {
        const errors = {};

        if (!values.nome) {
            errors.nome = 'É necessário informar o Nome';
        }

        if (!values.matricula) {
            errors.matricula = 'É necessário informar a Matrícula';
        }
        return errors;
    };

    return <FormUI
        validate={validate}
        entidade='Produtor'
        fields={[
            { label: 'Nome', name: 'nome' },
            { label: 'Fantasia', name: 'fantasia' },
            { label: 'Documento', name: 'cnpj_cpf' },
            { label: 'Inscrição Estadual', name: 'ie' },
            { label: 'Email', name: 'email' },
            { label: 'Matrícula', name: 'matricula' },
        ]}
        inicialValues={{
            uuid: v4(),
            nome: '',
            fantasia: '',
            cnpj_cpf: '',
            ie: '',
            email: '',
            matricula: ''
        }}
        resource='produtores'
    />
};