import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import FieldRequired from '../../common/FieldRequired';
import FieldInformation from '../../common/FieldInformation';

const DateInput = ({ onChange, field }) => {
    return (
        <React.Fragment>
            <FieldRequired field={field} onChange={onChange} />
            <FieldInformation field={field} onChange={onChange} />
        </React.Fragment >
    )
}
export default DateInput;