import React from "react";
import FieldInformation from "../../common/FieldInformation";
import FieldRequired from "../../common/FieldRequired";

const OptionsInput = ({ field, onChange, select }) => {
  return (
    <React.Fragment>
      <FieldRequired field={field} onChange={onChange} />
      <FieldInformation field={field} onChange={onChange} />
    </React.Fragment>
  );
};

export default OptionsInput;
