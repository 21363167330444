import Axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Table, Badge, CardFooter, Row, Col, CardTitle, Button } from 'reactstrap';
import useTimer from '../../../hooks/useTimer';
import Api from '../../../lib/Api';
import { formatToBrasilTimeZone } from '../../../lib/DateUtil';
const REFRESH_TIME = 60 * 1000; // 60segundos;
export const DispositivosLista = () => {

    const [isLoading, setIsloading] = useState(false);

    const [Dispositivos, setDispositivos] = useState([]);

    const fetch = async () => {
        setIsloading(true);

        const [res, error] = await Api.fetchDispositivos();

        setDispositivos(res);

        setIsloading(false);
    }

    const { refreshTimer } = useTimer({
        timer: REFRESH_TIME,
        onRefresh: fetch
    })
    useEffect(() => {
        fetch();
    }, []);

    if (isLoading) {
        return <div className="animated fadeIn pt-1 text-center"><div className="sk-spinner sk-spinner-pulse"></div></div>;
    }
    return (<>

        <Card className="animated fadeIn">
            <CardHeader className="d-flex justify-content-between align-items-center">
                <h2 className="font-weight-light">
                    Dispositivos

                </h2>
                <div>
                    <span className="text-muted mr-2">
                        Atualizando em {refreshTimer / 1000} segundos
                    </span>
                    <Button onClick={fetch} color="primary">
                        <i className="icon-refresh icons font-2xl"></i>
                    </Button>
                    <Link to={'/dispositivos/novo'} className="btn btn-secondary ml-2">
                        Novo
                    </Link>
                </div>
            </CardHeader>

            <CardBody>

                <Row>
                    {Dispositivos.map(dis => (
                        <Col xs="12" sm="6" md="4">

                            <Card className={dis.online ? 'card-accent-success' : 'card-accent-danger'}>
                                <CardHeader>
                                    {dis.descricao}
                                    <div className="card-header-actions">
                                        <Badge color={dis.online ? 'success' : 'danger'} >{dis.online ? 'Online' : 'Offline'}</Badge>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <div className='d-flex justify-content-between'>
                                        <div className='d-flex flex-column'>
                                            <span className='text-value'>{dis.ultimo_historico ? dis.ultimo_historico.valor : 'n/d'}</span>
                                            Temperatura
                                        </div>
                                        <div className='d-flex flex-column'>
                                            <div>
                                                Última Leitura:
                                            </div>
                                            {dis.ultimo_historico ? formatToBrasilTimeZone(dis.ultimo_historico.data_hora) : ''}
                                        </div>

                                    </div>
                                </CardBody>
                                <CardFooter>
                                    <div className='d-flex align-items-center justify-content-end' style={{ gap: '1rem' }}>
                                        <Link style={{ textDecoration: 'none' }} to={`/dispositivos/${dis.uuid}/historico`}>
                                            <i className="icon-graph icons font-2xl d-block "></i>
                                        </Link>
                                        <Link style={{ textDecoration: 'none' }} to={`/dispositivos/${dis.uuid}/editar`}>
                                            <i className="fa fa-pencil-square-o fa-lg  icons font-2xl d-block"></i>
                                        </Link>
                                    </div>
                                </CardFooter>
                            </Card>
                        </Col>
                    ))}

                </Row>
            </CardBody>
        </Card>
    </>
    )

}
