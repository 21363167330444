
import Axios from 'axios';
import React, { useEffect, useState, Component } from 'react';
import { v4 } from 'uuid';
import { Field, Form, Formik, ErrorMessage } from 'formik';
import {
    Button, Col, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row,
    Card, CardBody, CardHeader, CardTitle
} from 'reactstrap';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AppSwitch } from '@coreui/react';

import { Carousel, CarouselCaption, CarouselControl, CarouselIndicators, CarouselItem } from 'reactstrap';
import { Api, Loading } from '../../../lib';
import { ImageWithAuth } from '../../../components';

class Carousels extends Component {

    constructor(props) {
        super(props);
        this.state = { activeIndex: 0 };
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);
    }

    onExiting() {
        this.animating = true;
    }

    onExited() {
        this.animating = false;
    }

    next() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === this.props.items.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? this.props.items.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }

    goToIndex(newIndex) {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    }

    render() {
        const { activeIndex } = this.state;

        const slides2 = this.props.items.map((item) => {
            return (
                <CarouselItem
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                    key={item.url}
                >
                    <ImageWithAuth url={item.url} />
                    {/* <img className="d-block w-100" src={item.src} alt={item.altText} /> */}
                    <CarouselCaption captionText={item.caption} captionHeader={item.caption} />
                </CarouselItem>
            );
        });

        return (
            <div className="animated fadeIn" >
                <Row>
                    <Col xs="12" >
                        <Card>
                            <CardHeader>
                                <i className="fa fa-align-justify"></i><strong>Imagens</strong>
                            </CardHeader>
                            <CardBody>
                                <Carousel activeIndex={activeIndex} next={this.next} previous={this.previous}>
                                    <CarouselIndicators items={this.props.items} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
                                    {slides2}
                                    <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                                    <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
                                </Carousel>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

export const Equipamento = props => {

    const { uuid } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [setores, setSetores] = useState([]);
    const [modal, setModal] = useState(false);
    const [items, setItems] = useState([]);

    const [inicialValues, setValues] = useState({
        uuid: uuid ? uuid : v4(),
        descricao: '',
        tag: '',
        marca: '',
        capacidade: '0',
        localizacao: '',
        calibravel: true,
        modelo: '',
        setor_id: 0,
    });
    const toggle = () => setModal(p => !p);

    const history = useHistory();
    const [selectedFile, setSelectedFile] = useState([]);
    const handleInputChange = (event) => {
        setSelectedFile(event.target.files)
    }
    const submit = async () => {
        const data = new FormData()

        selectedFile.forEach((s, i) => {
            data.append(i, s)
        })

        toggle();
        setIsLoading(true);
        await Axios.post('/equipamentos/' + uuid + "?_method=PATCH", data, { headers: { "Content-Type": "multipart/form-data" } })
        await refresh();
        toast.success("Salvo com sucesso!");

    }

    const refresh = async () => {
        try {
            setIsLoading(true);
            const dadosSetores = await Api.fetchSetores({
                orderBy: 'descricao:asc'
            });
            setSetores(dadosSetores);

            if (uuid) {
                const dados = await Api.fetchEquipamento(uuid);
                setValues({
                    descricao: dados.descricao,
                    tag: dados.tag,
                    marca: dados.marca,
                    capacidade: dados.capacidade,
                    localizacao: dados.localizacao,
                    calibravel: Boolean(dados.calibravel),
                    modelo: dados.modelo,
                    setor_id: dados.setor_id
                });
                setItems(dados.imagens);
            }
        } catch (error) {
            toast.error(error.message);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        refresh();
    }, [])

    const validate = (values) => {
        const errors = {};

        if (!values.tag) {
            errors.tag = 'É necessário informar a TAG';
        }
        if (!values.descricao) {
            errors.descricao = 'É necessário informar a Descrição';
        }

        if (isNaN(Number(values.capacidade))) {
            errors.capacidade = 'Valor de capacidade inválido';
        }

        if (!values.setor_id) {
            errors.setor_id = 'É necessário informar o setor';
        }

        return errors;
    };


    if (isLoading) return <Loading />
    return (
        <>
            <Card>
                <CardHeader>
                    <CardTitle>
                        Equipamento
                    </CardTitle>
                    {uuid ?
                        <Button onClick={toggle}>
                            Anexar Imagens
                        </Button> : null}
                </CardHeader>
                <CardBody>
                    <Formik
                        initialValues={inicialValues}
                        enableReinitialize
                        validate={validate}
                        onSubmit={async (values, actions) => {

                            try {
                                if (uuid) {
                                    await Axios.patch('/equipamentos/' + uuid, values);
                                } else {
                                    await Axios.post('/equipamentos', values);
                                }
                                toast.success("Salvo com sucesso!");

                                history.push('/equipamentos');

                            } catch (error) {
                                toast.error(error.message);
                            }
                            actions.setSubmitting(false);
                        }}>
                        {({ values, errors, isSubmitting, setFieldValue }) => (

                            <Form>
                                <FormGroup>
                                    <Label>Descrição</Label>
                                    <Field className="form-control" autoFocus name="descricao" />
                                    <ErrorMessage className='mt-2 text-danger' name="descricao" component="div" />
                                </FormGroup>
                                <FormGroup>
                                    <Label>TAG / Código</Label>
                                    <Field className="form-control" name="tag" />
                                    <ErrorMessage className='mt-2 text-danger' name="tag" component="div" />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Marca</Label>
                                    <Field className="form-control" name="marca" />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Modelo</Label>
                                    <Field className="form-control" name="modelo" />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Capacidade</Label>
                                    <Field className="form-control" name="capacidade" />
                                    <ErrorMessage className='mt-2 text-danger' name="capacidade" component="div" />
                                </FormGroup>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label>Setor</Label>
                                            <Field className="form-control" name="setor_id" as='select'>
                                                <option value=""></option>
                                                {setores.map(setor => (
                                                    <option value={setor.id}>{setor.descricao}</option>
                                                ))}
                                            </Field>
                                            <ErrorMessage className='mt-2 text-danger' name="setor_id" component="div" />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label>Detalhes da Localização</Label>
                                            <Field className="form-control" name="localizacao" />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                {/* <FormGroup>
                                <Label className="align-text-bottom">
                                    <Field name="calibravel" type="checkbox" />{' '}
                                    Calibrável
                                </Label>
                            </FormGroup> */}


                                <FormGroup>
                                    <Label className="align-items-center d-flex">
                                        <Field name="calibravel" component={CustomInputComponent} />{' '}
                                        Calibrável
                                    </Label>
                                </FormGroup>

                                {/* 
                            <FormGroup>
                                <Label >
                                    Fotos
                                </Label>
                                <input
                                    multiple
                                    className='form-control'
                                    accept='image/*'
                                    id="file"
                                    name="file"
                                    type="file" onChange={(event) => {
                                        setFieldValue("file", event.currentTarget.files);
                                    }} />
                            </FormGroup> */}

                                <FormGroup>
                                    <Button type="submit" disabled={isSubmitting}>Salvar</Button>
                                </FormGroup>
                            </Form>
                        )}
                    </Formik>

                    <Carousels items={items} />
                </CardBody>
            </Card>
            <Modal isOpen={modal} toggle={toggle} >
                <ModalHeader toggle={toggle}>Anexar Imagens</ModalHeader>
                <ModalBody>
                    <label className="text-white">Select File :</label>
                    <input type="file" accept='image/*' multiple className="form-control" name="upload_file" onChange={handleInputChange} />
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={submit}>Salvar</Button>{' '}
                    <Button color="secondary" onClick={toggle}>Cancelar</Button>
                </ModalFooter>
            </Modal>
        </>
    )
};


const CustomInputComponent = ({
    field, // { name, value, onChange, onBlur }
    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <AppSwitch className={'mx-1'} variant={'pill'} color={'primary'} checked={field.value}  {...field} {...props} />
);