import React from 'react';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { v4 } from 'uuid';
import { Form, Field, Formik } from 'formik';
import { Card, CardBody, CardHeader, CardTitle } from '../../../components/ui/Card';
import { Button, FormGroup, Label } from 'reactstrap';
import Api from '../../../lib/Api';
import { toast } from 'react-toastify';
import Loading from '../../../lib/Loading';
import { useEffect } from 'react';

export const DinamicoRegistrosFrm = props => {

    const { uuid, tabela_uuid } = useParams();

    const [isLoading, setIsLoading] = useState(true);

    const history = useHistory();

    const [registro, setRegistro] = useState({
        uuid: v4(),
        descricao: '',
        tabela_uuid: tabela_uuid,
    })

    useEffect(() => {

        (async _ => {

            try {
                setIsLoading(true)
                if (uuid) {
                    const req = await Api.fetchDinamicoRegistro(uuid)
                    setRegistro({
                        uuid: req.uuid,
                        descricao: req.descricao,
                        tabela_uuid: req.tabela_uuid
                    })
                }
            } catch (error) {
                toast.error(error.message);
            } finally {
                setIsLoading(false)
            }

        })();

    }, [])



    if (isLoading) {
        return <Loading />
    }

    return (
        <>
            <Card>
                <CardHeader>
                    <CardTitle>
                        Registro
                    </CardTitle>
                </CardHeader>
                <CardBody>

                    <Formik
                        initialValues={registro}
                        onSubmit={async (values, actions) => {

                            try {
                                if (uuid) {
                                    await Api.atualizarRegistroDinamico(uuid, values);
                                } else {
                                    await Api.adicionarRegistroDinamico(values);
                                }

                                toast.success("Salvo com sucesso!");
                                history.push('/dinamico/tabelas/editar/' + tabela_uuid);
                            } catch (error) {
                                toast.error(error.message);
                            } finally {
                                actions.setSubmitting(false);
                            }

                        }}>

                        {({ isSubmitting }) => (
                            <Form>
                                <FormGroup>
                                    <Label>Descrição</Label>
                                    <Field name="descricao" className="form-control" />
                                </FormGroup>
                                <FormGroup>
                                    <Button disabled={isSubmitting} type="submit">Salvar</Button>
                                </FormGroup>
                            </Form>
                        )}
                    </Formik>
                </CardBody>
            </Card>
        </>
    )
};