import { applyMiddleware, combineReducers, createStore } from '@reduxjs/toolkit';
import ReduxThunk from 'redux-thunk';

import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

import authReducer from './reducers/auth';
import navigationReducer from './reducers/navigation';
import parametrosReducer from './reducers/parametros';


const rootReducer = combineReducers({
    auth: authReducer,
    navigation: navigationReducer,
    parametros: parametrosReducer
});

const persistConfig = {
    key: 'autocontrole',
    whitelist: ['auth'],
    storage
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = createStore(persistedReducer, applyMiddleware(ReduxThunk));

let persistor = persistStore(store)

export { store, persistor };