import React from 'react';
import { FormGroup, Input, Label, Row, Col } from 'reactstrap';
import FieldInformation from '../../common/FieldInformation';

const SwitchInput = ({ field, onChange, onLabelChange }) => {

    let {  score } = field;

    const scoreSafe = score ? score : [0, 0];

    const changeHandler = (position, value) => {
        const scoreCopy = [...scoreSafe];
        scoreCopy[position] = value;
        onChange({ score: scoreCopy });
    }

    return (
        <React.Fragment>

            <Row form>
                <Col xs={12} md={6} lg={3} >
                    <FormGroup>
                        <Label>Pontuação NC: </Label>
                        <Input type="number" value={scoreSafe[0]} onChange={t => changeHandler(0, t.target.value)} />
                    </FormGroup>
                </Col>
                <Col xs={12} md={6} lg={3} >
                    <FormGroup>
                        <Label>Pontuação C: </Label>
                        <Input type="number" value={scoreSafe[1]} onChange={t => changeHandler(1, t.target.value)} />
                    </FormGroup>
                </Col>
            </Row>
            <FieldInformation field={field} onChange={onChange} />

        </React.Fragment>
    )
}

export default SwitchInput;