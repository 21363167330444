import React from 'react';
import { Input, FormGroup, Label } from 'reactstrap';

const Diario = ({ frequencia, onChange }) => {

    return (
        <FormGroup>
            <Label>Horário</Label>
            <Input type="time" value={frequencia ? frequencia.horario : ''} onChange={onChange} />
        </FormGroup>
    )

};
const Semanal = ({ frequencia, onChange, onChangeHorario }) => {

    const diasSemana = [
        { texto: 'Segunda', value: 'segunda' },
        { texto: 'Terça', value: 'terca' },
        { texto: 'Quarta', value: 'quarta' },
        { texto: 'Quinta', value: 'quinta' },
        { texto: 'Sexta', value: 'sexta' },
        { texto: 'Sábado', value: 'sabado' },
        { texto: 'Domingo', value: 'domingo' },
    ];

    return (
        <>
            <FormGroup>
                <Label className='mr-3'>Dias da semana</Label>
                {diasSemana.map(d =>
                    <FormGroup check inline>
                        <Label check>
                            <Input type="checkbox"
                                value={d.value}
                                onChange={(e) => onChange(d.value, e.target.checked)}
                                checked={frequencia ? frequencia[d.value] : false}
                            />
                            {d.texto}
                        </Label>
                    </FormGroup>
                )}
            </FormGroup>
            <FormGroup>
                <Label>Horário</Label>
                <Input type="time" value={frequencia ? frequencia.horario : ''} onChange={onChangeHorario} />
            </FormGroup>
        </>
    )
};

const Mensal = ({ frequencia, onChange, onChangeHorario }) => {


    const mes = [];
    for (let i = 1; i <= 31; i++) {
        mes.push(i);
    }

    return (
        <>
            <FormGroup>
                <Label className='mr-3'>Dias do mês</Label>
                {mes.map(d =>
                    <FormGroup check inline>
                        <Label check>
                            <Input
                                checked={frequencia ? frequencia[d] : false}
                                type="checkbox"
                                onChange={e => onChange(d, e.target.checked)}
                            /> {d}
                        </Label>
                    </FormGroup>
                )}
            </FormGroup>
            <FormGroup>
                <Label>Horário</Label>
                <Input type="time" value={frequencia ? frequencia.horario : ''} onChange={onChangeHorario} />
            </FormGroup>
        </>
    )

};

export { Diario, Semanal, Mensal };