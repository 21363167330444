import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import TableTeamplate from '../../../components/Templates/Table/TableTeamplate';
import { Card, CardBody, CardHeader } from '../../../components/ui/Card';
import { Formik, Form, Field } from 'formik';
import { Button, Col, FormGroup, Label, Row } from 'reactstrap';
import { v4 } from 'uuid';
import moment from 'moment';
import { useEffect } from 'react';
import Axios from 'axios';
import { toast } from 'react-toastify';
import Loading from '../../../lib/Loading';
export const ProgramacaoEquipamentoForm = props => {

    const { equipamentoUuid, uuid, tipo } = useParams();
    const [equipamentos, setEquipamentos] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const history = useHistory();

    const [values, setValues] = useState({
        equipamento_uuid: equipamentoUuid,
        uuid: uuid ? uuid : v4(),
        atividade: '',
        detalhamento: '',
        periodicidade: 1,
        inicio: moment().format('YYYY-MM-DD'),
        tipo: tipo
    })


    useEffect(() => {

        (async _ => {

            try {

                setIsLoading(true);
                var reqEqp = await Axios.get('/equipamentos');
                setEquipamentos(reqEqp.data);

                if (uuid) {
                    var req = await Axios.get('/equipamentos/' + equipamentoUuid + '/programacoes/' + uuid);
                    setValues(req.data);
                }

            } catch (error) {
                toast.error(error.message);
            } finally {
                setIsLoading(false);
            }

        })();

    }, [])


    if (isLoading) return <Loading />

    return (
        <>
            <Card>
                <CardHeader>
                    <h2>Programação Equipamento </h2>
                </CardHeader>
                <CardBody>
                    <Formik initialValues={values} enableReinitialize onSubmit={async (values, actions) => {
                        try {
                            let req;
                            if (uuid) {
                                req = await Axios.patch(`/equipamentos/${equipamentoUuid}/programacoes/${uuid}`, values);
                            } else {
                                req = await Axios.post(`/equipamentos/${equipamentoUuid}/programacoes`, values);
                            }
                            history.push(`/equipamentos/${equipamentoUuid}/programacoes`);
                            toast.success(req.data.mensagem);
                        } catch (error) {
                            toast.error(error.message);
                        } finally {
                            actions.setSubmitting(false);
                        }

                    }}>
                        {({ isSubmitting }) => (
                            <Form>

                                <FormGroup>
                                    <Label>Equipamento</Label>
                                    <Field name="equipamento_uuid" className="form-control" as="select" readOnly value={equipamentoUuid}>
                                        {equipamentos.map(eq => (
                                            <option value={eq.uuid}>{eq.descricao}</option>
                                        ))}
                                    </Field>
                                </FormGroup>

                                <FormGroup>
                                    <Label>Atividade</Label>
                                    <Field name="atividade" className="form-control" />
                                </FormGroup>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label>Data Inicial</Label>
                                            <Field name="inicio" type="date" className="form-control" />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label>Periodicidade</Label>
                                            <Field name="periodicidade" type="number" className="form-control" />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <FormGroup>
                                    <Label>Detalhamento</Label>
                                    <Field name="detalhamento" className="form-control" as="textarea" rows={6} />
                                </FormGroup>

                                <FormGroup>
                                    <Button type="submit" disabled={isSubmitting}>Salvar</Button>
                                </FormGroup>

                            </Form>
                        )}

                    </Formik>

                </CardBody>
            </Card>
        </>
    )
};