import React from "react";
import {
  FormGroup,
  Button,
  Row,
  Col,
  Label,
  Input,
  Card,
  CardBody,
} from "reactstrap";
import { v4 } from "uuid";

const OptionsTab = ({ field, onChange, select }) => {
  if (!field.values) field.values = [];
  const { values, name } = field;
  const createOpt = () => {
    const count = values ? values.length + 1 : 1;
    const uuid = v4();
    return {
      id: uuid,
      label: `Opção ${count}`,
      value: `opt_${uuid}`,
      selected: false,
      nao_conforme: false,
      score: 0,
    };
  };

  const newOptHanler = () => {
    const newOptions = values ? [...values, createOpt()] : [createOpt()];

    onChange({ values: newOptions });
  };

  const labelChangeHandler = (index, label) => {
    const updatedOptions = [...values];

    updatedOptions[index].label = label;

    onChange({ values: updatedOptions });
  };

  const changeHandler = (id, key, value) => {
    const updatedOptions = [...values];

    const index = updatedOptions.findIndex((p) => p.id === id);

    updatedOptions[index][key] = value;
    onChange({ values: updatedOptions });
  };
  const deleteHandler = (id) => {
    const updatedOptions = values.filter((p) => p.id !== id);
    onChange({ values: updatedOptions });
  };
  return (
    <React.Fragment>
      <FormGroup row className="align-items-center">
        <Label xs={6} sm={2} className="font-weight-bold">
          Opções:
        </Label>
        <Col xs={6} sm={2}>
          <Button
            type="button"
            color="primary"
            size="sm"
            onClick={newOptHanler}
          >
            Adicionar
          </Button>
        </Col>
      </FormGroup>

      {values &&
        values.map((opt, index) => {
          return (
            <>
              <div key={opt.id}>
                <Row form>
                  <Col xs={12} md={3}>
                    <FormGroup>
                      <Label>Descrição:</Label>
                      <Input
                        stype="text"
                        value={opt.label}
                        onChange={(t) =>
                          labelChangeHandler(index, t.target.value)
                        }
                      />
                    </FormGroup>
                  </Col>

                  <Col xs={12} md={3}>
                    <FormGroup>
                      <Label>Pontuação:</Label>
                      <Input
                        type="number"
                        value={opt.score}
                        onChange={(t) =>
                          changeHandler(opt.id, "score", t.target.value)
                        }
                      />
                    </FormGroup>
                  </Col>

                  <Col
                    xs={12}
                    md={3}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <FormGroup style={{ marginTop: 0 }}>
                      <Input
                        type="checkbox"
                        name={`nao_conforme${opt.id}`}
                        id={`nao_conforme${opt.id}`}
                        value={opt.nao_conforme}
                        onChange={(t) =>
                          changeHandler(
                            opt.id,
                            "nao_conforme",
                            t.target.checked
                          )
                        }
                        checked={Boolean(opt.nao_conforme)}
                      />
                      <Label for={`nao_conforme${opt.id}`}>Não Conforme</Label>
                    </FormGroup>
                  </Col>
                  <Col
                    xs={12}
                    md={3}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <Button
                      onClick={() => deleteHandler(opt.id)}
                      type="button"
                      size="sm"
                      color="danger"
                    >
                      <i className="fa fa-ban"></i>
                    </Button>
                  </Col>
                </Row>
              </div>
              <hr />
            </>
          );
        })}
    </React.Fragment>
  );
};

export default OptionsTab;
