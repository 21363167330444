import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
const useNavigationRefresh = (refresh) => {
    const location = useLocation();
    useEffect(() => {
        if (location.state && location.state.refresh) {
            refresh();
        }
    }, [])
};
export default useNavigationRefresh