import { Form, Formik, Field } from 'formik';
import { Button, Col, FormGroup, Label, Row } from 'reactstrap';
import React from 'react';
import { Component } from 'react';
import ReactQuill from 'react-quill';
import {
    Card,
    CardHeader,
    CardBody
} from "reactstrap";
import 'quill/dist/quill.snow.css';
import { v4 } from 'uuid';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import Loading from '../../../lib/Loading';
import Axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment';

const TextEditors = ({ value, onChange, onBlur, ...props }) => {

    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            ['blockquote', 'code-block'],
            [{ 'header': 1 }, { 'header': 2 }],               // custom button values
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
            [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
            [{ 'direction': 'rtl' }],                         // text direction
            [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            [{ 'font': [] }],
            [{ 'align': [] }],
            ['clean']                                         // remove formatting button
        ]
    }
    return (
        <ReactQuill
            onChange={onChange}
            value={value} modules={modules}
        />
    )
}

export const Treinamento = props => {


    const history = useHistory();
    const { uuid } = useParams();

    const [values, setValues] = useState({
        uuid: v4(),
        conteudo: '',
        descricao: '',
        duracao_horas: 0,
        duracao_minutos: 0,
        validade: 0
    });

    const query = gql`
        query getTreinamento($uuid:String){
            treinamento(uuid:$uuid){
                uuid,
                conteudo,
                descricao,
                duracao,
                validade
            }
        }`
    const [getTreinamento, { loading, data, error }] = useLazyQuery(query)
    useEffect(() => {

        (async _ => {
            if (uuid) {
                var resp = await getTreinamento({
                    variables: {
                        uuid: uuid
                    }
                });
                setValues({
                    conteudo: resp.data.treinamento.conteudo,
                    descricao: resp.data.treinamento.descricao,
                    duracao_minutos: moment(resp.data.treinamento.duracao, "HH-mm-ss").minutes(),
                    duracao_horas: moment(resp.data.treinamento.duracao, "HH-mm-ss").hours(),
                    uuid: resp.data.treinamento.uuid,
                    validade: resp.data.treinamento.validade,
                })
            }
        })();

    }, []);

    if (loading) {
        return <Loading />
    }

    return (
        <>
            <Card>
                <CardHeader>
                    <h2>Treinamento</h2>
                </CardHeader>
                <CardBody>
                    <Formik
                        onSubmit={async (values) => {
                            try {
                                if (uuid) {
                                    await Axios.patch('/treinamentos/' + uuid, values);
                                } else {
                                    await Axios.post('/treinamentos', values)
                                }
                                toast.success('Salvo com sucesso!');
                                history.push('/treinamentos')
                            } catch (error) {
                                toast.error(error.message);
                            }

                        }}
                        initialValues={values} enableReinitialize>
                        {({ setFieldValue, values, isSubmitting }) => (
                            <Form>
                                <FormGroup>
                                    <Label>Descrição</Label>
                                    <Field className="form-control" name="descricao" />
                                </FormGroup>
                                <FormGroup>
                                    <Row>
                                        <Col>
                                            <Row>
                                                <Col>
                                                    <Label>Duração (horas)</Label>
                                                    <Field className="form-control" name="duracao_horas" type="number" />
                                                </Col>
                                                <Col>
                                                    <Label>Duração (minutos)</Label>
                                                    <Field className="form-control" name="duracao_minutos" type="number" min="0" max="59" />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col>
                                            <Label>Validade</Label>
                                            <Field className="form-control" name="validade" type="number" min="0" />
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Descrição</Label>
                                    <TextEditors
                                        value={values.conteudo}
                                        onChange={(content) => setFieldValue('conteudo', content)} />
                                </FormGroup>

                                <FormGroup>
                                    <Button disabled={isSubmitting} type="submit">Salvar</Button>
                                </FormGroup>
                            </Form>
                        )}
                    </Formik>
                </CardBody>
            </Card>
        </>
    )
};