import React from 'react';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';
import { TableTeamplate } from '../../../components/Templates';
export const Propriedades = props => {

    const headers = [
        {
            label: 'Produtor',
            filtravel: true,
            name: 'produtor_nome'
        },
        {
            label: 'Propriedade',
            filtravel: true,
            name: 'propriedade'
        },
        {
            label: 'Latitude',
            filtravel: true,
            name: 'latitude'
        },
        {
            label: 'Longitude',
            filtravel: true,
            name: 'longitude'
        },
        {
            label: 'Matrícula',
            filtravel: true,
            name: 'matricula'
        },
        {
            label: 'Classificação',
            filtravel: true,
            name: 'classificacao_pqfl',
            type: 'select',
            values: [
                {
                    descricao: 'Todos',
                    value: ''
                },
                {
                    descricao: 'A',
                    value: 'A'
                },
                {
                    descricao: 'B',
                    value: 'B'
                },
                {
                    descricao: 'C',
                    value: 'C'
                },

            ]
        },
        {
            label: 'Status',
            filtravel: true,
            name: 'ativo',
            type: 'select',
            values: [
                {
                    descricao: 'Todos',
                    value: ''
                },
                {
                    descricao: 'Ativos',
                    value: '1'
                },
                {
                    descricao: 'Inativos',
                    value: '0'
                },

            ]
        },
        {
            label: "Ações"
        }
    ];


    const classificacao_pqflColors = {
        "A": "success",
        "B": "warning",
        "C": "danger"
    }

    const body = [
        (a) => a.produtor.nome,
        'propriedade',
        'latitude',
        'longitude',
        'matricula',
        (a) => <Badge pill color={classificacao_pqflColors[a.classificacao_pqfl]}>{a.classificacao_pqfl}</Badge>,
        (a) => <Badge color={a.ativo ? 'success' : 'secondary'}>{a.ativo ? 'Ativo' : 'Inativo'}</Badge>,
        (cadastro) => <Link to={`/propriedades/editar/${cadastro.uuid}`}>
            <i className="cui-note icons font-2xl "></i>
        </Link>
    ]

    return (
        <>
            <TableTeamplate
                titulo='Propriedades'
                headers={headers}
                rota={'propriedades'}
                body={body}
                end_point={'/propriedades'} />
        </>
    )
};