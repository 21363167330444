import { gql, useQuery } from '@apollo/client';
import React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Card, CardBody, Button, Badge, CardHeader, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Spinner } from 'reactstrap';
import LogModal from '../../../components/LogModal';
import { Table, Thead } from '../../../components/ui/Table';
import useNavigationRefresh from '../../../hooks/useNavigationRefresh';
import usePooling from '../../../hooks/usePooling';
import useToggle from '../../../hooks/useToggle';
import Api from '../../../lib/Api';
import { formatToBrasilTimeZone } from '../../../lib/DateUtil';
import Loading from '../../../lib/Loading';


const Query = gql`
    query GetUsuarios{
        colaboradores(first:10000,page:1){
            data{
                id,
                nome,
                email,
                ativo, 
                app_versao,
                uuid,
                ultimo_acesso
            }
       
        }
    }
`


export const ColaboradorLista = props => {
    const [colaboradorId, setColaboradorId] = useState();
    const { toggle: toggleModal, state: modalOpen } = useToggle(false);

    const { loading, data, error, refetch, startPolling, stopPolling } = useQuery(Query);

    usePooling(startPolling, stopPolling);

    useNavigationRefresh(refetch);

    if (error) {
        toast.error(error.message);
        return null;
    }

    if (loading) return <Loading />

    const toggleStatus = async (cadastro) => {
        try {
            await Api.atualizarColaborador(cadastro.uuid, { ativo: !cadastro.ativo });
            await refetch();
            toast.success("Operação Realizada com sucesso");
        } catch (error) {
            toast.error(error.message);
        }
    }

    return (
        <>
            <LogModal isOpen={modalOpen} registroId={colaboradorId} toggle={toggleModal} tabela={'colaboradores'} />
            <Card>
                <CardHeader>
                    <h2 className='d-inline font-weight-light'>Usuários</h2>
                    <div className="card-header-actions">
                        <Link className='btn btn-primary' to={'/usuarios/novo'}>Novo</Link>
                    </div>

                </CardHeader>

                <CardBody>
                    <Table>
                        <Thead>
                            <tr>
                                <th>Nome</th>
                                <th>Email</th>
                                <th>Status</th>
                                <th>Último Acesso</th>
                                <th>Versão App.</th>
                                <th>Ações</th>
                            </tr>
                        </Thead>
                        <tbody>
                            {data.colaboradores.data.map(c => (
                                <tr>
                                    <td>{c.nome}</td>
                                    <td>{c.email}</td>
                                    <td>
                                        <Badge color={`${c.ativo ? 'success' : 'danger'}`}>
                                            {c.ativo ? 'Ativo' : 'Inativo'}
                                        </Badge>
                                    </td>
                                    <td>{c.ultimo_acesso ? formatToBrasilTimeZone(c.ultimo_acesso) : ''}</td>
                                    <td>{c.app_versao}</td>
                                    <td>
                                        <Acoes
                                            toggleStatus={toggleStatus}
                                            cadastro={c}
                                            setColaboradorId={setColaboradorId}
                                            toggleModal={toggleModal} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
        </>
    )

}


const Acoes = ({ cadastro, setColaboradorId, toggleModal, toggleStatus }) => {

    const { state: dropdownOpen, toggle } = useToggle(false);
    const user = useSelector(state => state.auth);

    const { toggle: toggleSpinner, state: spinner } = useToggle(false)

    const { isAdmin, uuid } = user;

    const history = useHistory();
    const clickHandler = async () => {
        toggleSpinner();
        await toggleStatus(cadastro)
        toggleSpinner();
    }

    const ChangeStatusButton = () => {
        if (!isAdmin) return null;
        if (cadastro.uuid === uuid) return null;

        if (cadastro.ativo) {
            return <DropdownItem onClick={clickHandler}>
                Inativar
            </DropdownItem>
        }

        return <DropdownItem onClick={clickHandler}>
            Ativar
        </DropdownItem >
    }

    return <div className='d-flex align-items-center'>

        {spinner ?
            <Spinner color="secondary" />
            : <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle color="link">
                    <i className="icon-options-vertical icons font-2xl"></i>
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem header>Ações</DropdownItem>
                    <DropdownItem onClick={() => { history.push(`/usuarios/editar/${cadastro.uuid}`) }}>
                        Editar
                    </DropdownItem>
                    {/* <DropdownItem divider /> */}
                    <DropdownItem
                        onClick={() => {
                            toggleModal();
                            setColaboradorId(cadastro.id);
                        }}
                    >
                        Log
                    </DropdownItem>
                    <ChangeStatusButton />
                </DropdownMenu>
            </ButtonDropdown>
        }
    </div >
}