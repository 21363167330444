import React from "react";
import {
  FormGroup,
  Label,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Row,
} from "reactstrap";
import * as uuid from "uuid";

const PossiveisNaoConformidades = ({ onChange, field }) => {
  if (!field.possiveis_nao_conformidades)
    field.possiveis_nao_conformidades = [];

  let { id, possiveis_nao_conformidades } = field;

  const newOptHanler = () => {
    possiveis_nao_conformidades.push({
      uuid: uuid.v4(),
      descricao: "",
    });
    onChange({
      possiveis_nao_conformidades: possiveis_nao_conformidades,
    });
  };

  const changeHandler = (index, field, value) => {
    possiveis_nao_conformidades[index][field] = value;

    onChange({
      possiveis_nao_conformidades: possiveis_nao_conformidades,
    });
  };

  const deletarHandler = (uuid) => {
    onChange({
      possiveis_nao_conformidades: possiveis_nao_conformidades.filter(
        (p) => p.uuid != uuid
      ),
    });
  };

  return (
    <>
      <FormGroup row className="align-items-center">
        <Label xs={6} sm={2} className="font-weight-bold">
          Possiveis não conformidades:
        </Label>
        <Col xs={6} sm={2}>
          <Button
            type="button"
            color="primary"
            size="sm"
            onClick={newOptHanler}
          >
            Adicionar
          </Button>
        </Col>
      </FormGroup>
      <Row>
        {!!possiveis_nao_conformidades &&
          possiveis_nao_conformidades.map((e, i) => (
            <div
              key={e.uuid}
              className="p-2 col-12 col-md-6 d-flex align-items-center mb-2"
              style={{ gap: "1rem" }}
            >
              <Input
                type="text"
                placeholder="descrição"
                value={e.descricao}
                onChange={(t) => changeHandler(i, "descricao", t.target.value)}
              />
              <Button
                onClick={() => deletarHandler(e.uuid)}
                type="button"
                size="sm"
                color="danger"
              >
                <i className="fa fa-ban"></i>
              </Button>
            </div>
          ))}
      </Row>{" "}
    </>
  );
};

export default PossiveisNaoConformidades;
