import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import image from "../assets/img/Login/fundo-pac.jpg";
import { LoginForm } from "../features";
import { getEstaAutenticado } from "../store/reducers/auth";

const LoginPage = (props) => {
  const jaEstaAutenticado = useSelector(getEstaAutenticado);

  if (jaEstaAutenticado) {
    return <Redirect to={"/dashboard"} />;
  }

  const bgStyle = {
    backgroundImage: "url(" + image + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundAttachment: "fixed",
    backgroundPosition: "center",
  };

  return (
    <div className="app flex-row align-items-center" style={bgStyle}>
      <Container fluid>
        <Row className="justify-content-center">
          <Col md="5" lg="5" xl="8"></Col>
          <Col col="12" sm="12" md="6" lg="5" xl="3">
            <LoginForm />
          </Col>
          <Col></Col>
        </Row>
      </Container>
    </div>
  );
};

export default LoginPage;
