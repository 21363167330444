import { ErrorMessage, Field, Form, Formik } from 'formik';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Card, CardHeader, FormGroup, Label } from 'reactstrap';
import CardBody from 'reactstrap/lib/CardBody';

import { v4 as uuidV4 } from 'uuid';
import Api from '../../../lib/Api';
import Loading from '../../../lib/Loading';
export const Dispositivo = props => {

    const { uuid } = useParams();

    const [isLoading, setIsLoading] = useState(true);

    const [success, setSuccess] = useState(false);

    const [dispositivo, setDispositivo] = useState({
        uuid: uuid ? uuid : uuidV4(),
        descricao: '',
        end_point: '',
        cron: '',
        timeout: 25
    });

    useEffect(() => {

        (async _ => {
            try {
                setIsLoading(true);

                if (uuid) {

                    const data = await Api.fetchDispositivo(uuid);
                    setDispositivo(data);

                }
            } catch (error) {
                toast.error("Houve um erro ao buscar dados do dispositivo");
            }
            setIsLoading(false);
        })();

    }, [])

    const handleSubmit = async (values, { setIsSubmitting }) => {


        let error;

        if (uuid) {
            const [data, error1] = await Api.atualizarDispositivo(values);
            error = error1;
        } else {
            const [data, error1] = await Api.adicionarDispositivo(values);
            error = error1;
        }
        if (!error) {
            setSuccess(true);
            toast.success("Salvo com sucesso!");
        } else {
            toast.error("Houve um erro! " + error);
        }
    }

    if (isLoading) {
        return <Loading />
    }


    if (success) {
        return <Redirect to={'/dispositivos'} />
    }
    return (
        <>
            <Card>
                <CardHeader>
                    <h2>{uuid ? `Editar` : 'Novo' + ' Dispositivo'}</h2>
                </CardHeader>

                <CardBody>

                    <Formik enableReinitialize validate={values => {
                        const errors = {};
                        if (!values.descricao) {
                            errors.descricao = "É necessário informar a descrição";
                        }
                        return errors;
                    }} initialValues={dispositivo} onSubmit={handleSubmit}>
                        {({ values, isSubmitting }) => (
                            <Form>
                                <FormGroup>
                                    <Label htmlFor="descricao">Descricação</Label>
                                    <Field name="descricao" id="descricao" className="form-control" />
                                    <ErrorMessage name='descricao' component='div' className='text-danger mt-2' />
                                </FormGroup>

                                {/* <FormGroup>
                                    <Label htmlFor="end_point">URL</Label>
                                    <Field name="end_point" id="end_point" className="form-control" />
                                </FormGroup>

                                <FormGroup>
                                    <Label htmlFor="cron" >Execução</Label>
                                    <Field name="cron" id="cron" placeholder="* * * * *" className="form-control" />
                                </FormGroup>

                                <FormGroup>
                                    <Label htmlFor="timeout" >Timeout (em minutos)</Label>
                                    <Field type="number" name="timeout" id="timeout" className="form-control" />
                                </FormGroup> */}

                                <FormGroup>
                                    <button className='btn btn-secondary' disabled={isSubmitting} type="submit">Salvar</button>
                                </FormGroup>

                            </Form>
                        )}
                    </Formik>
                </CardBody>
            </Card>
        </>
    )
};
//   <div>
//                                     <h5><i className="cui-settings icons font-xl">{' '}</i>Configuração de execução</h5>
//                                     <FormGroup row className='align-items-sc'>
//                                         <Col xs={3}>
//                                             <div className="bg-gray-100 p-2 ">
//                                                 <h6>Minutos</h6>
//                                                 <FormGroup check>
//                                                     <Label check>
//                                                         <Field type="radio" name="minutos" value="*" className={'form-check-input'} />{' '}
//                                                         Todo minuto
//                                                     </Label>
//                                                 </FormGroup>

//                                                 <FormGroup check>
//                                                     <Label check>
//                                                         <Field type="radio" name="minutos" value="*/5" className={'form-check-input'} />{' '}
//                                                         A cada 5 minutos
//                                                     </Label>
//                                                 </FormGroup>

//                                                 <FormGroup check>
//                                                     <Label check>
//                                                         <Field type="radio" name="minutos" value="*/15" className={'form-check-input'} />{' '}
//                                                         A cada 15 minutos
//                                                     </Label>
//                                                 </FormGroup>

//                                                 <FormGroup check>
//                                                     <Label check>
//                                                         <Field type="radio" name="minutos" value="*/30" className={'form-check-input'} />{' '}
//                                                         A cada 30 minutos
//                                                     </Label>
//                                                 </FormGroup>
//                                             </div>
//                                         </Col>
//                                         <Col xs={3}>
//                                             <div className="bg-gray-100 p-2 h-100">
//                                                 <h6>Horas</h6>
//                                                 <FormGroup check>
//                                                     <Label check>
//                                                         <Field type="radio" name="horas" value="*" className={'form-check-input'} />{' '}
//                                                         Toda hora
//                                                     </Label>
//                                                 </FormGroup>

//                                                 <FormGroup check>
//                                                     <Label check>
//                                                         <Field type="radio" name="horas" value="*/6" className={'form-check-input'} />{' '}
//                                                         A cada 6 horas
//                                                     </Label>
//                                                 </FormGroup>

//                                                 <FormGroup check>
//                                                     <Label check>
//                                                         <Field type="radio" name="horas" value="*/12" className={'form-check-input'} />{' '}
//                                                         A cada 12 horas
//                                                     </Label>
//                                                 </FormGroup>
//                                             </div>
//                                         </Col>
//                                         <Col xs={3}>
//                                             <div className="bg-gray-100 p-2 h-100">
//                                                 <h6>Dias</h6>
//                                                 <FormGroup check>
//                                                     <Label check>
//                                                         <Field type="radio" name="dias" value="*" className={'form-check-input'} />{' '}
//                                                         Todo dia
//                                                     </Label>
//                                                 </FormGroup>

//                                                 <FormGroup check>
//                                                     <Label check>
//                                                         <Field type="radio" name="dias" value="MON-FRI" className={'form-check-input'} />{' '}
//                                                         Apenas dias da semana
//                                                     </Label>
//                                                 </FormGroup>
//                                             </div>
//                                         </Col>
//                                     </FormGroup>
//                                 </div>