import Axios from 'axios';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardHeader, FormGroup, Label } from 'reactstrap';
import { v4 } from 'uuid';


import { gql, useLazyQuery, useQuery } from '@apollo/client';
import Loading from '../../../lib/Loading';
const GetGrupoQuery = gql`
query GetGrupo($uuid: String){
    grupo(uuid:$uuid){
        descricao,
        uuid
    }
}
`
export const GrupoFormulario = () => {

    const [inicialValues, setInicialValues] = useState({ descricao: '', uuid: v4() });
    const { uuid } = useParams();
    const history = useHistory();

    const [isLoading, setIsLoading] = useState(true);

    const [GetGrupo] = useLazyQuery(GetGrupoQuery, { variables: { uuid: uuid }, fetchPolicy: "no-cache" })


    useEffect(() => {

        (async _ => {

            try {
                if (uuid) {
                    const { data } = await GetGrupo();
                    setInicialValues({
                        descricao: data.grupo.descricao,
                        uuid: data.grupo.uuid
                    });
                }
            } catch (error) {
                toast.error(error.message);
            }
            finally {
                setIsLoading(false);
            }

        })();

    }, [])

    if (isLoading) return <Loading />

    return (
        <Card>
            <CardHeader >
                <h2>{`Grupo`}</h2>
            </CardHeader>
            <CardBody>
                <Formik
                    enableReinitialize
                    initialValues={inicialValues}
                    onSubmit={async (values, actions) => {
                        try {
                            if (uuid) {
                                const req = await Axios.patch('/grupos' + '/' + uuid, values);
                                toast.success(req.data.mensagem);
                            } else {
                                const req = await Axios.post('/grupos', values);
                                toast.success(req.data.mensagem);
                            }
                        } catch (error) {
                            toast.error(error.message);
                        }
                        actions.setSubmitting(false);
                        history.push({ pathname: '/grupos', state: { refresh: true } });
                    }}>
                    {({ values, isSubmitting, errors, touched }) => (
                        <Form>
                            <FormGroup >
                                <Label>Descrição</Label>
                                <Field required name={'descricao'} className="form-control" autoFocus />
                            </FormGroup>
                            <FormGroup>
                                <Button type="submit" disabled={isSubmitting}>Salvar</Button>
                            </FormGroup>
                        </Form>
                    )}
                </Formik>
            </CardBody>
        </Card >

    )
    // return <FormUI
    //     entidade='Grupo'
    //     fields={[{ label: 'Descrição', name: 'descricao' }]}
    //     inicialValues={{ descricao: '', uuid: v4() }}
    //     resource='grupos' />

}