import React from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'reactstrap';

import Graficos from './Graficos';
import Vistorias from './Vistorias';


export const VistoriasPAC = props => {

    const { esqueletoUuid } = useParams();

    return (
        <div className="">
            <Row>
                <Col>
                    <Vistorias esqueletoUuid={esqueletoUuid} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Graficos esqueletoUuid={esqueletoUuid} />
                </Col>
            </Row>
        </div>
    )
}

