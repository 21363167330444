import Axios from "axios";
import Api from "../../lib/Api";

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const SET_JWT = 'SET_JWT';

export const login = (email, senha) => {

    return async (dispatch) => {

        const data = {
            email: email,
            senha: senha,
            plataforma: "web"
        };
        const [dados, error] = await Api.login(data);
        if (error) {
            throw error;
        }
        dispatch({ type: LOGIN, payload: dados});
    }
};

export const logout = () => {
    localStorage.removeItem('jwt');
    return { type: LOGOUT };
}
export const setJWT = jwt => {
    return { type: SET_JWT, jwt: jwt }
}