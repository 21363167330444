import Axios from 'axios';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalHeader, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import TableBody from '../../../components/Templates/Table/TableBody';
import TableFooter from '../../../components/Templates/Table/TableFooter';
import TableHeaders from '../../../components/Templates/Table/TableHeaders';
import { Card, CardActions, CardBody, CardHeader, CardTitle } from '../../../components/ui/Card';
import { Table } from '../../../components/ui/Table';
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import Api from '../../../lib/Api';
import { toast } from 'react-toastify';
import ModalPreenchidos from '../../../components/Templates/ModalPreenchidos';



export const EquipamentoLista = props => {

    const [equipamentos, setEquipamentos] = useState([]);
    const [dropDownOpen, setDropDownOpen] = useState(false);
    const toggle = () => setDropDownOpen(s => !s);
    const [selectedIds, setSelectedIds] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const [equipamentoId, setEquipamentoId] = useState();

    const [modalEquipamento, setModalEquipamento] = useState(false);

    const modalEquipamentoToggle = (equipamentoId = null) => {
        setEquipamentoId(equipamentoId);
        setModalEquipamento(p => !p);
    }



    const fetch = async (filtros) => {
        try {
            setIsLoading(true);

            let url = '/equipamentos?';

            if (filtros) {
                for (const key in filtros) {
                    if (Object.hasOwnProperty.call(filtros, key)) {
                        const element = filtros[key];
                        if (element) {
                            url += `${key}=${element}&`;
                        }
                    }
                }
            }
            const req = await Axios.get(url);
            setEquipamentos(req.data);
        } catch (error) {
            toast.error(error.message);
        } finally {
            setIsLoading(false);
        }

    };
    useEffect(() => {
        fetch();
    }, [])

    const headers = [
        { label: 'Descrição', filtravel: true, name: 'descricao' },
        { label: 'Tag / Código', filtravel: true, name: 'tag' },
        { label: 'Marca', filtravel: true, name: 'marca' },
        { label: 'Modelo', filtravel: true, name: 'modelo' },
        { label: 'Capacidade', filtravel: true, name: 'capacidade' },
        { label: 'Setor', name: 'setor_nome', filtravel: true },
        { label: 'Detalhe Localizacação', filtravel: true, name: 'localizacao' },
        { label: 'Calibrável' },
        { label: "Ações" }
    ]

    const body = [
        'descricao',
        'tag',
        'marca',
        'modelo',
        'capacidade',
        (a) => a.setor ? a.setor.descricao : '',
        'localizacao',
        a => a.calibravel ? 'Sim' : 'Não',
        (cadastro) => (
            <div className='d-flex align-items-center'>
                <Link to={`/equipamentos/editar/${cadastro.uuid}`}>
                    <i className={'cui-note font-2xl'}></i>
                </Link>
                <Link to={`/equipamentos/${cadastro.uuid}/programacoes`}>
                    <i className={'ml-2 cui-calendar font-2xl'}></i>
                </Link>
                <Button color="link" className="pl-2 py-0" onClick={() => modalEquipamentoToggle(cadastro.id)}>
                    <i className="cui-layers icons font-2xl"></i>
                </Button>
            </div >)
    ]

    const GERAR_QR_CODE = async () => {

        const [data, error] = await Api.GerarQRCodes('equipamento', selectedIds);

        if (error) {
            toast.error("Houve um erro na impressão");
            return;
        }

        var blob = new Blob([data], {
            type: 'application/pdf'
        });

        var url = window.URL.createObjectURL(blob)

        window.open(url);
    }

    return (
        <>
            <Card>
                <CardHeader>
                    <CardTitle>
                        Equipamentos
                    </CardTitle>

                    <CardActions>
                        <ButtonDropdown isOpen={dropDownOpen} toggle={toggle}>
                            <DropdownToggle caret>
                                Ações
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem disabled={selectedIds.length === 0} onClick={GERAR_QR_CODE}>{'Gerar QR-code'}</DropdownItem>
                            </DropdownMenu>
                        </ButtonDropdown>

                        <Link className='btn btn-primary' to={`/equipamentos/novo`}>
                            Novo
                        </Link>
                    </CardActions>
                </CardHeader>
                <CardBody>
                    <Table>
                        <TableHeaders headers={headers} onFiltroChange={fetch} showFiltro={true} isLoading={isLoading} />
                        <TableBody
                            onRowSelect={(uuid) => {

                                if (selectedIds.includes(uuid)) {
                                    setSelectedIds(prev => prev.filter(s => s !== uuid));
                                } else {
                                    setSelectedIds(
                                        prev => [...prev, uuid]
                                    )
                                }

                            }}
                            selectedIds={selectedIds}
                            isLoading={isLoading}
                            cadastros={equipamentos}
                            body={body}
                        />
                        <TableFooter cadastros={equipamentos} />
                    </Table>
                </CardBody>
            </Card>
            {equipamentoId ?
                <ModalPreenchidos
                    filtros={{ equipamentoId: equipamentoId }}
                    isOpen={modalEquipamento}
                    toggle={modalEquipamentoToggle} /> : null}
        </>
    )

};

