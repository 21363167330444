import React from 'react';
import { FormGroup, Label, Input, Row, Col } from 'reactstrap';
import FieldRequired from '../../common/FieldRequired';
import FieldInformation from '../../common/FieldInformation';

const NumberInput = ({ field, onChange }) => {
    const { value, min, max } = field;

    return (
        <React.Fragment>

            <Row>
                <Col xs="4">
                    <FormGroup>
                        <Label >Valor:</Label>
                        <Input type="number" name="" id="" onChange={(t) => onChange({ value: t.target.value })} value={value} />
                    </FormGroup>
                </Col>
                <Col xs="4">
                    <FormGroup>
                        <Label>Mínimo:</Label>
                        <Input type="number" name="" id="" onChange={(t) => onChange({ min: t.target.value })} value={min} />
                    </FormGroup>
                </Col>
                <Col xs="4">
                    <FormGroup>
                        <Label>Máximo:</Label>
                        <Input type="number" name="" id="" onChange={(t) => onChange({ max: t.target.value })} value={max} />
                    </FormGroup>
                </Col>
            </Row>

            <FieldRequired field={field} onChange={onChange} />
            <FieldInformation field={field} onChange={onChange} />
        </React.Fragment >
    )
}

export default NumberInput;