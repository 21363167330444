import Axios from 'axios';
import moment from 'moment-timezone';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Badge, Card, CardBody, CardHeader, Table, Pagination, PaginationItem, PaginationLink } from 'reactstrap';

import { formatToBrasilTimeZone } from '../../../lib/DateUtil';
import Api from '../../../lib/Api';
import Loading from '../../../lib/Loading';
export const DispositivoHistorico = props => {

    const { uuid } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [historico, setHistorico] = useState({});
    const [urlRequest, setUrlRequest] = useState(`/dispositivos/${uuid}/historico`);
    const [dispositivo, setDispostivo] = useState({});

    useEffect(() => {

        (async _ => {
            try {
                if (!urlRequest) return;
                setIsLoading(true);
                const data = await Axios.get(urlRequest);
                const d = await Api.fetchDispositivo(uuid);
                setDispostivo(d);
                setHistorico(data.data);
            } catch (error) {
                toast.error("Houve um erro ao buscar os dados de histórico. " + error.message);
            }
            setIsLoading(false);

        })();
    }, [urlRequest]);

    if (isLoading) {
        return <Loading />
    }

    const MappedLinks =
        historico.links.map(l => {
            if (l.label === 'pagination.previous')
                return <PaginationItem>
                    <PaginationLink previous tag="button" onClick={() => setUrlRequest(l.url)} />
                </PaginationItem>

            if (l.label === 'pagination.next')
                return <PaginationItem>
                    <PaginationLink next tag="button" onClick={() => setUrlRequest(l.url)} />
                </PaginationItem>
            return (
                <PaginationItem active={l.active}>
                    <PaginationLink tag="button" onClick={() => setUrlRequest(l.url)}>
                        {l.label}
                    </PaginationLink>
                </PaginationItem>
            )
        })


    return (
        <>
            <Card>
                <CardHeader>
                    <h2>{dispositivo.descricao}</h2>
                </CardHeader>
                <CardBody>
                    <Table>
                        <thead>
                            <tr>
                                <th>Data</th>
                                <th>Valor</th>
                                <th>Conformidade</th>
                            </tr>
                        </thead>
                        <tbody>
                            {historico.data.map(h => (
                                <tr key={h.id}>
                                    <td>{formatToBrasilTimeZone(h.data_hora)}</td>
                                    <td>{h.valor}</td>
                                    <td>{Boolean(h.conformidade) ? <Badge color="success"> Conforme</Badge> : <Badge color="danger">Não conforme</Badge>}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <Pagination className="d-flex justify-content-center">
                        {MappedLinks}
                    </Pagination>
                </CardBody>
            </Card>
        </>
    )
};