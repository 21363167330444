import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import FieldInformation from '../../common/FieldInformation';
import FieldRequired from '../../common/FieldRequired';

const ImageInput = ({ field, onLabelChange, onChange }) => {

    const { label } = field;

    return (

        <React.Fragment>

            <FieldRequired field={field} onChange={onChange} />

            <FieldInformation field={field} onChange={onChange} />

        </React.Fragment>
    )
}

export default ImageInput;