import React from 'react';
import FieldInformation from '../../common/FieldInformation';
import FieldRequired from '../../common/FieldRequired';
const DateTimeInput = ({ field, onChange }) => {
    
    return (
        <>

            <FieldRequired field={field} onChange={onChange} />
            <FieldInformation field={field} onChange={onChange} />
        </>
    )
};
export default DateTimeInput