import React from "react";
import { login } from "../../../store/actions/auth";
import Logo from "../../../../src/assets/img/brand/lacteus_avatar_200x200.png";
import { useState } from "react";
import { useReducer } from "react";
import { useDispatch } from "react-redux";
import {
  Alert,
  Spinner,
  Button,
  Card,
  CardBody,
  Col,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import RecuperarSenhaModal from "./RecuperarSenhaModal";

export const LoginForm = (props) => {
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [showResetModalPasswordModal, setShowResetPasswordModal] =
    useState(false);

  const inputChangeHandler = (event, inputName) => {
    dispatchFormState({
      type: "UPDATE_INPUT",
      inputName: inputName,
      inputValue: event.target.value,
    });
  };

  const reducer = (state, action) => {
    if (action.type === "UPDATE_INPUT") {
      return { ...state, [action.inputName]: action.inputValue };
    }

    return state;
  };

  const [formState, dispatchFormState] = useReducer(
    reducer,

    {
      login: "",
      senha: "",
    }
  );

  const dispatch = useDispatch();

  const loginHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await dispatch(login(formState.login, formState.senha));
    } catch (error) {
      setError(error.message);
      setTimeout(() => {
        setError("");
      }, 3000);
    }
    setIsLoading(false);
  };

  return (
    <Card className="p-4">
      {showResetModalPasswordModal && (
        <RecuperarSenhaModal
          email={formState.login}
          centered
          isOpen={showResetModalPasswordModal}
          toggle={() => setShowResetPasswordModal((p) => !p)}
        />
      )}
      <CardBody>
        <Form onSubmit={loginHandler}>
          <div className="text-center">
            <h1 className="font-weight-light">Autocontrole</h1>
            <img src={Logo} alt="logo" className="mb-3 mt-3" />
          </div>
          <p className="text-muted">Entre com a sua conta</p>
          <InputGroup className="mb-3">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="icon-user"></i>
              </InputGroupText>
            </InputGroupAddon>
            <Input
              type="text"
              placeholder="Usuário"
              autoComplete="username"
              onChange={(e) => inputChangeHandler(e, "login")}
            />
          </InputGroup>
          <InputGroup className="mb-4">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="icon-lock"></i>
              </InputGroupText>
            </InputGroupAddon>
            <Input
              type="password"
              placeholder="Senha"
              autoComplete="current-password"
              onChange={(e) => inputChangeHandler(e, "senha")}
            />
          </InputGroup>
          <Row>
            <Col xs="6" className="text-left">
              <Button
                color="link"
                className="px-0"
                onClick={setShowResetPasswordModal.bind(this, true)}
              >
                Esqueceu a senha?
              </Button>
            </Col>
            <Col xs="6" className="text-right">
              <Button
                color="primary"
                type="submit"
                className="px-4"
                onClick={loginHandler}
              >
                {isLoading ? <Spinner size="sm" className="mr-1" /> : "Login"}
              </Button>
            </Col>
          </Row>

          {error && (
            <Alert color="danger" className="mt-2">
              {error}
            </Alert>
          )}
        </Form>
      </CardBody>
    </Card>
  );
};
