import React from "react";
import {
  FormGroup,
  Label,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Row,
} from "reactstrap";
import * as uuid from "uuid";

const PossiveisAcoesCorretivas = ({ onChange, field }) => {
  if (!field.possiveis_acoes_corretivas) field.possiveis_acoes_corretivas = [];

  let { id, possiveis_acoes_corretivas } = field;

  const newOptHanler = () => {
    possiveis_acoes_corretivas.push({
      uuid: uuid.v4(),
      descricao: "",
    });
    onChange({
      possiveis_acoes_corretivas: possiveis_acoes_corretivas,
    });
  };

  const deletarHandler = (uuid) => {
    onChange({
      possiveis_acoes_corretivas: possiveis_acoes_corretivas.filter(
        (p) => p.uuid != uuid
      ),
    });
  };

  const changeHandler = (index, field, value) => {
    possiveis_acoes_corretivas[index][field] = value;

    onChange({
      possiveis_acoes_corretivas: possiveis_acoes_corretivas,
    });
  };
  return (
    <>
      <FormGroup row className="align-items-center">
        <Label xs={6} sm={2} className="font-weight-bold">
          Possíveis ações corretivas:
        </Label>
        <Col xs={6} sm={2}>
          <Button
            type="button"
            color="primary"
            size="sm"
            onClick={newOptHanler}
          >
            Adicionar
          </Button>
        </Col>
      </FormGroup>
      <Row>
        {!!possiveis_acoes_corretivas &&
          possiveis_acoes_corretivas.map((e, i) => (
            <div
              key={e.uuid}
              className="col-12 col-md-6 d-flex mb-2 align-items-center"
              style={{ gap: "1rem" }}
            >
              <Input
                type="text"
                placeholder="descrição"
                value={e.descricao}
                onChange={(t) => changeHandler(i, "descricao", t.target.value)}
              />
              <Button
                onClick={() => deletarHandler(e.uuid)}
                type="button"
                size="sm"
                color="danger"
              >
                <i className="fa fa-ban"></i>
              </Button>
            </div>
          ))}
      </Row>
    </>
  );
};
export default PossiveisAcoesCorretivas;
