import { Field, Formik } from 'formik';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
    Card,
    CardBody,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    CardHeader,
    Spinner,
    Row,
    Col,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Nav
} from 'reactstrap';

import { v4 as uuidV4 } from 'uuid';
import FlashMessage from '../../../lib/FlashMessage';
import Loading from '../../../lib/Loading';
import Api from '../../../lib/Api';
export const Colaborador = (props) => {

    const isAdmin = useSelector(state => state.auth.isAdmin);
    const { colaboradorUuid } = useParams();
    const [isLoading, setIsLoading] = useState(true);

    const [success, setSuccess] = useState(false);

    const [isSubmiting, setIsSubmintg] = useState(false);

    const [error, setError] = useState();
    const [activeTab, setActiveTab] = useState('1');

    const [permissoesAtivas, setPermissoesAtivas] = useState([]);

    const [colaborador, setColaborador] = useState({
        ativo: true,
        uuid: uuidV4(),
        nome: "",
        email: "",
        documento: "",
        telefone: "",
        data_nascimento: "",
        senha: "",
        permissoes: []
    });

    const [permissoes, setPermissoes] = useState([]);
    useEffect(() => {

        (async _ => {

            if (colaboradorUuid) {
                const [data, error] = await Api.fetchColaborador(colaboradorUuid);

                if (!error)
                    setColaborador(data);

                const [permissoesData, permissoesErro] = await Api.fetchPermissoes();
                if (!permissoesErro) {
                    setPermissoes(permissoesData);
                }

                const permissoes_ativas = data.permissoes.map(p => p.id.toString());
                setPermissoesAtivas(permissoes_ativas);
            }
            setIsLoading(false);

        })();


    }, [])


    const inputChangeHandler = (name, value) => {
        setColaborador(c => {
            return { ...c, [name]: value };
        })
    }

    const submitHandler = async event => {

        event.preventDefault();
        setIsSubmintg(true);

        try {
            if (colaboradorUuid) {
                await Api.atualizarColaborador(colaboradorUuid, colaborador);
            } else {
                await Api.adiconarColaborador(colaborador)
            }
            toast.success("Salvo com sucesso!");
            setSuccess(true);
        } catch (error) {
            setError(error.message)
        }

        setIsSubmintg(false);
    }

    const toggle = (tab) => {
        if (tab != activeTab) setActiveTab(tab);
    }

    if (isLoading) {
        return <Loading />
    }

    if (success) {
        return <Redirect to={{ pathname: "/usuarios", state: { refresh: true } }} />
    }

    return (
        <div className="animated fadeIn">

            <Row>
                <Col xs="12" className="mb-4">
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                active={activeTab === '1'}
                                onClick={() => { toggle('1'); }}
                            >
                                Principal
                            </NavLink>
                        </NavItem>
                        {isAdmin ?
                            <NavItem>
                                <NavLink
                                    disabled={!Boolean(colaboradorUuid)}
                                    active={activeTab === '2'}
                                    onClick={() => { toggle('2'); }}
                                >
                                    Permissões
                                </NavLink>
                            </NavItem>
                            : null}
                    </Nav>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                            <Card>
                                <CardHeader>
                                    <h2 className="font-weight-light d-inline">
                                        {`${colaboradorUuid ? 'Editar Colaborador' : 'Novo Colaborador'}`}
                                    </h2>
                                </CardHeader>
                                <CardBody>
                                    <Form className="mb-3" onSubmit={e => submitHandler(e)}>
                                        <FormGroup>
                                            <Label htmlFor="nome">Nome</Label>
                                            <Input type="text" name="nome" value={colaborador.nome} onChange={e => inputChangeHandler("nome", e.target.value)} />
                                        </FormGroup>

                                        <FormGroup>
                                            <Label htmlFor="email">Email</Label>
                                            <Input type="text" name="email" value={colaborador.email} onChange={e => inputChangeHandler("email", e.target.value)} />
                                        </FormGroup>

                                        <FormGroup>
                                            <Label htmlFor="senha">Senha</Label>
                                            <Input type="password" name="senha" value={colaborador.senha} placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;" onChange={e => inputChangeHandler("senha", e.target.value)} />
                                        </FormGroup>

                                        <FormGroup>
                                            <Label htmlFor="data_nascimento">Data de Nascimento</Label>
                                            <Input type="date" name="data_nascimento" value={colaborador.data_nascimento} onChange={e => inputChangeHandler("data_nascimento", e.target.value)} />
                                        </FormGroup>

                                        <FormGroup>
                                            <Label htmlFor="documento">CPF</Label>
                                            <Input type="text" name="documento" value={colaborador.documento} onChange={e => inputChangeHandler("documento", e.target.value)} />
                                        </FormGroup>

                                        <FormGroup>
                                            <Label htmlFor="telefone">Telefone</Label>
                                            <Input type="text" name="telefone" value={colaborador.telefone} onChange={e => inputChangeHandler("telefone", e.target.value)} />
                                        </FormGroup>

                                        <FormGroup>
                                            <Label className="d-block">Função</Label>
                                            <FormGroup check inline className="mt-0">
                                                <Input className="form-check-input" type="checkbox" id="inline-checkbox1" name="inline-checkbox1" value="option1" />
                                                <Label className="form-check-label" check htmlFor="inline-checkbox1">Executor</Label>
                                            </FormGroup>
                                            <FormGroup check inline className="mt-0">
                                                <Input className="form-check-input" type="checkbox" id="inline-checkbox2" name="inline-checkbox2" value="option2" />
                                                <Label className="form-check-label" check htmlFor="inline-checkbox2">Monitor</Label>
                                            </FormGroup>
                                            <FormGroup check inline className="mt-0">
                                                <Input className="form-check-input" type="checkbox" id="inline-checkbox3" name="inline-checkbox3" value="option3" />
                                                <Label className="form-check-label" check htmlFor="inline-checkbox3">Verificador</Label>
                                            </FormGroup>
                                        </FormGroup>


                                        <Button type="submit">{isSubmiting ? <i className="fa fa-spinner"></i> : 'Salvar'}</Button>
                                    </Form>
                                    {error && <FlashMessage type="danger" mensagem={error} onEnd={() => setError()} />}
                                </CardBody>
                            </Card>
                        </TabPane>
                        <TabPane tabId="2" >
                            <Card>
                                <CardHeader>
                                    <h2 className="font-weight-light d-inline">
                                        Editar Colaborador - Permissões
                                    </  h2>
                                </CardHeader>
                                <CardBody>
                                    <Formik
                                        initialValues={{ permissoes: permissoesAtivas }}
                                        onSubmit={async (values, actions) => {
                                            const [data, error] = await Api.setPermissoes(values, colaboradorUuid);
                                            if (error) {
                                                toast.error("Erro ao alterar permissão. " + error.message);
                                            } else {
                                                toast.success("Permissões alteradas com sucesso");
                                            }
                                            actions.setSubmitting(false);
                                        }}>
                                        {props => {
                                            return (
                                                <form onSubmit={props.handleSubmit}>
                                                    {permissoes.map(p => (
                                                        <FormGroup check key={p.id}>
                                                            <Field type="checkbox" id={p.id} value={p.id} name="permissoes" className="form-check-input" checked={props.values.permissoes.includes(p.id.toString())} />
                                                            <Label htmlFor={p.id}>{p.permissao}</Label>
                                                        </FormGroup>
                                                    ))}
                                                    <Button type="submit">{props.isSubmitting ? <i className="fa fa-spinner"></i> : 'Salvar'}</Button>
                                                </form>
                                            )
                                        }}
                                    </Formik>
                                    {error && <FlashMessage type="danger" mensagem={error} onEnd={() => setError()} />}
                                </CardBody>
                            </Card>
                        </TabPane>
                    </TabContent>

                </Col>
            </Row>
        </div >

    )

};

