import React from 'react';
import FieldInformation from '../../common/FieldInformation';
import FieldRequired from '../../common/FieldRequired';

const TextInput = ({ field, onChange }) => {

    return (
        <React.Fragment>
            <FieldRequired field={field} onChange={onChange} />
            <FieldInformation field={field} onChange={onChange} />
        </React.Fragment >
    )
}

export default TextInput;