import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import FieldInformation from '../../common/FieldInformation';

const HeaderInput = ({ onChange, subtype, field }) => {

    const changeTypeHandler = subtype => {
        onChange({ subtype: subtype });
    }

    return (
        <React.Fragment>
            <FormGroup check inline >
                <Label>Tipo:</Label>
            </FormGroup>
            <FormGroup check inline >
                <Label check >
                    <Input value='h1' label="h1" type={'radio'} id='h1' name='subtype' onChange={t => changeTypeHandler(t.target.value)} checked={subtype === "h1"} />
                    h1
                </Label>
            </FormGroup>

            <FormGroup check inline >
                <Label check >
                    <Input value='h2' label="h2" type={'radio'} id='h2' name='subtype' onChange={t => changeTypeHandler(t.target.value)} checked={subtype === "h2"} />
                    h2
                </Label>
            </FormGroup>

            <FormGroup check inline >
                <Label check >
                    <Input value='h3' label="h3" type={'radio'} id='h3' name='subtype' onChange={t => changeTypeHandler(t.target.value)} checked={subtype === "h3"} />
                    h3
                </Label>
            </FormGroup>

            <FormGroup check inline >
                <Label check >
                    <Input value='h4' label="h4" type={'radio'} id='h4' name='subtype' onChange={t => changeTypeHandler(t.target.value)} checked={subtype === "h4"} />
                    h4
                </Label>
            </FormGroup>
            <FieldInformation field={field} onChange={onChange} />
        </React.Fragment>
    )
}

export default HeaderInput;