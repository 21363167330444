
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
    Card,
    CardBody,
    CardHeader,
    Table,
    Modal,
    Button,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Label,
    Col,
    FormGroup,
    Spinner,
    Row
} from 'reactstrap';

import moment from 'moment';

import { Formik, Form, Field } from 'formik';
import { toast } from 'react-toastify';
import Api from '../../../lib/Api';
import { useDispatch } from 'react-redux';
import { loadNavigation } from '../../../store/actions/navigation';
import { formatToBrasilTimeZone } from '../../../lib/DateUtil';

export const Revisoes = props => {

    const [isLoading, setIsLoading] = useState(true);
    const [novasNaoConformidades, setNovasNaoConformidades] = useState([]);
    const [modal, setModal] = useState({
        open: false,
        naoConformidade: null
    });

    const dispatch = useDispatch();

    const atualizar = async () => {

        setIsLoading(true);

        const [dataPNC, error3] = await Api.fetchNaoConformidades();

        await dispatch(loadNavigation());

        if (error3) {
            toast.error("Houve algum erro ao buscar dados");
            setIsLoading(false);
            return;
        }

        setNovasNaoConformidades(dataPNC);
        setIsLoading(false);

    }

    useEffect(() => {
        atualizar();
    }, [])

    const toggleModal = () => setModal(p => !p);
    const onNaoConformidadeClickHandler = (naoConformidade) => {
        setModal({ open: true, naoConformidade: naoConformidade });
    }

    const aprovarNaoConformidadeHandler = async values => {

        const [resp, error] = await Api.aprovarNaoConformidade(values);

        if (error) {
            toast.error("Houve um erro ao revisar! " + error.message);
        } else {
            toast.success(resp.mensagem);
        }

        await atualizar();

        toggleModal();
    }

    const reprovarNaoConformidadeHandler = async uuid => {
        const [resp, error] = await Api.reprovarNaoConformidade(uuid);
        if (error) {
            toast.error("Houve um erro ao revisar! " + error.message);
        } else {
            toast.success(resp.mensagem);
        }
        await atualizar();
        toggleModal();

    }

    if (isLoading) {
        return <div className="animated fadeIn pt-1 text-center"><div className="sk-spinner sk-spinner-pulse"></div></div>;
    }

    return (
        <>
            <Card className="animated fadeIn">
                <CardHeader className="d-flex  justify-content-between">
                    <h2 className="font-weight-light">Revisões</h2>
                </CardHeader>
                <CardBody>
                    <Table hover responsive className="table-outline mb-0 d-sm-table">
                        <thead className="thead-light">
                            <tr>
                                <th>Planilha</th>
                                <th>Data</th>   
                                <th>Pergunta</th>
                                <th>Tipo</th>
                                <th>Descrição</th>
                                <th>Revisar</th>
                            </tr>
                        </thead>
                        <tbody>
                            {novasNaoConformidades.map(n => (
                                <tr key={n.uuid}>
                                    <td>{n.esqueleto_nome}</td>
                                    <td>{formatToBrasilTimeZone(n.monitoria_data)} </td>
                                    <td>{n.pergunta_label}</td>
                                    <td>{n.tipo_descricao}</td>
                                    <td>{n.descricao}</td>
                                    <td>
                                        <Button onClick={onNaoConformidadeClickHandler.bind(this, n)} color="link">
                                            <i className="cui-pencil icons font-xl d-block"></i>
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </CardBody>

                <Modal isOpen={modal.open} toggle={toggleModal}>
                    <Formik
                        onSubmit={aprovarNaoConformidadeHandler}
                        enableReinitialize
                        initialValues={{
                            descricao: modal.naoConformidade ? modal.naoConformidade.descricao : '',
                            uuid: modal.naoConformidade ? modal.naoConformidade.uuid : ''
                        }}>
                        {({ isSubmitting, values }) => (
                            <Form >

                                <ModalHeader toggle={toggleModal} >
                                    <div className="d-flex align-items-center" style={{ gap: '1rem' }}>
                                        Revisão de {modal.naoConformidade ? modal.naoConformidade.tipo_descricao : ''}
                                    </div>
                                </ModalHeader>

                                <ModalBody>

                                    <FormGroup>
                                        <Label>Planilha:</Label>
                                        <Field className="form-control" disabled value={modal ? modal.naoConformidade.esqueleto_nome : ''} />
                                    </FormGroup>

                                    <FormGroup>
                                        <Label>Pergunta:</Label>
                                        <Field className="form-control" disabled value={modal ? modal.naoConformidade.pergunta_label : ''} />
                                    </FormGroup>

                                    <FormGroup row>
                                        <Col xs={12} >
                                            <div className="d-flex justify-content-between">
                                                <Label>
                                                    Data:
                                                </Label>

                                            </div>
                                        </Col>
                                        <Col xs={12}>
                                            <Field type='text' className="form-control" disabled name="monitoria_data" value={modal && modal.naoConformidade.monitoria_data ? moment(modal.naoConformidade.monitoria_data).format('DD/MM/YYYY HH:mm:ss') : ''} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup>

                                        <Label>Monitor: </Label>
                                        <Field type='text' className="form-control" disabled name="monitor" value={modal ? modal.naoConformidade.monitor_nome : ''} />

                                    </FormGroup>

                                    <FormGroup>
                                        <Label>Descrição original: </Label>

                                        <Field className="form-control" disabled name="descricao_original" value={modal ? modal.naoConformidade.descricao : ''} />

                                    </FormGroup>

                                    <FormGroup>
                                        <Label>Descrição: </Label>
                                        <Field className="form-control" name="descricao" value={values.descricao} />
                                    </FormGroup>

                                </ModalBody>

                                <ModalFooter>
                                    <div style={{ position: 'absolute', left: '1rem' }}>
                                        {modal && modal.naoConformidade.monitoria_uuid &&
                                            <Link target={"_blank"} to={`/vistorias/${modal ? modal.naoConformidade.esqueleto_uuid : ''}/realizada/${modal ? modal.naoConformidade.monitoria_uuid : ''}`}>
                                                <span className='mr-2'>Visualizar planilha preenchida</span>
                                                <i className="fa fa-external-link"></i>
                                            </Link>
                                        }
                                    </div>
                                    <Button color="success" type='submit' disabled={isSubmitting}>
                                        {isSubmitting ? <Spinner size={'sm'} /> : 'Aprovar'}
                                    </Button>
                                    <Button color="danger" onClick={reprovarNaoConformidadeHandler.bind(this, values.uuid)}>Reprovar</Button>

                                </ModalFooter>

                            </Form>
                        )}
                    </Formik>
                </Modal>
            </Card >
        </>
    )
};