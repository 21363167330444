import Axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import { Button, Input } from "reactstrap";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Api from "../../../lib/Api";
import { toast } from "react-toastify";
import {
  Card,
  CardHeader,
  CardTitle,
  CardActions,
  CardBody,
} from "../../ui/Card/index";
import { Table, Thead } from "../../ui/Table/index";
import TableHeaders from "./TableHeaders";
import TableBody from "./TableBody";
import TableFooter from "./TableFooter";

const TableTeamplate = ({
  rota = "",
  end_point = "",
  titulo = "",
  entidade = "",
  headers = [],
  body = [],
  acoes = [],
  acoesPreDefinidas = [],
  podeSelecionar = true,
  body_href_index = 0,
  showFiltro = true,
  showEdit = true,
  showNovo = true,
  ...props
}) => {
  const [cadastros, setCadastros] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [dropDownOpen, setDropDownOpen] = useState(false);

  const [selectedIds, setSelectedIds] = useState([]);

  const history = useHistory();

  const temAcoes = acoes.length > 0 || acoesPreDefinidas.length > 0;

  const toggle = () => {
    setDropDownOpen(!dropDownOpen);
  };

  const onRowSelect = (uuid) => {
    if (selectedIds.includes(uuid)) {
      setSelectedIds(selectedIds.filter((p) => p != uuid));
    } else {
      setSelectedIds([...selectedIds, uuid]);
    }
  };

  const fetch = async (filtros) => {
    setIsLoading(true);

    let url = end_point + "?";

    if (filtros) {
      for (const key in filtros) {
        if (Object.hasOwnProperty.call(filtros, key)) {
          const element = filtros[key];
          if (element) url += `${key}=${element}&`;
        }
      }
    }

    const req = await Axios.get(url);

    setCadastros(req.data);
    setIsLoading(false);
  };

  useEffect(() => {
    fetch();
  }, []);

  const GERAR_QR_CODE = async () => {
    const [data, error] = await Api.GerarQRCodes(entidade, selectedIds);

    if (error) {
      toast.error("Houve um erro na impressão");
      return;
    }

    var blob = new Blob([data], {
      type: "application/pdf",
    });

    var url = window.URL.createObjectURL(blob);

    window.open(url);
  };

  const newClickHandler = () => {
    const href = `/${rota}/novo`;
    history.push(href);
  };

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle>{titulo}</CardTitle>

          <CardActions>
            {temAcoes && (
              <ButtonDropdown isOpen={dropDownOpen} toggle={toggle}>
                <DropdownToggle caret>Ações</DropdownToggle>
                <DropdownMenu>
                  {acoesPreDefinidas.map((acao) => {
                    return acao === "GERAR_QR_CODE" ? (
                      <DropdownItem
                        disabled={selectedIds.length === 0}
                        onClick={GERAR_QR_CODE}
                      >
                        {"Gerar QR-code"}
                      </DropdownItem>
                    ) : null;
                  })}
                  {acoes.map((acao) => (
                    <DropdownItem
                      onClick={acao.onClick.bind(this, selectedIds)}
                    >
                      {acao.label}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </ButtonDropdown>
            )}
            {showNovo && (
              <Button color="primary" onClick={newClickHandler}>
                Novo
              </Button>
            )}
          </CardActions>
        </CardHeader>
        <CardBody>
          <Table>
            <TableHeaders
              showFiltro={showFiltro}
              headers={headers}
              onFiltroChange={fetch}
              isLoading={isLoading}
            />
            <TableBody
              body={body}
              body_href_index={body_href_index}
              cadastros={cadastros}
              onRowSelect={onRowSelect}
              isLoading={isLoading}
              rota={rota}
              selectedIds={selectedIds}
            />
            <TableFooter cadastros={cadastros} />
          </Table>
        </CardBody>
      </Card>
    </>
  );
};

export default TableTeamplate;
