import React from 'react';
const TableFooter = ({ cadastros }) => {
    return (
        <tfoot className='bg-gray-200'>
            <tr >
                <td colSpan='100%' className='text-right font-weight-bold text-black-50  pr-2'>
                    Total Registros: {cadastros.length}
                </td>
            </tr>
        </tfoot>
    )
};
export default TableFooter