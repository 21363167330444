import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { v4 } from 'uuid';
import FormUI from '../../../components/Templates/Form';
import { Loading } from '../../../lib';

export const PropriedadesForm = props => {

    const [produtores, setProdutores] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {

        (async _ => {
            setIsLoading(true);
            const data = await Axios.get("/produtores");
            const values = data.data.map(d => ({ uuid: d.uuid, descricao: d.nome }));
            setProdutores(values);
            setIsLoading(false);
        })();

    }, [])

    if (isLoading) {
        return <Loading />
    }

    const validate = (values) => {
        const errors = {};

        if (!values.produtor_uuid) {
            errors.produtor_uuid = 'É necessário informar o Produtor';
        } 
     
        return errors;
    };
    return <FormUI
    validate={validate}
        entidade='Propriedade'
        fields={[
            { label: 'Produtor', name: 'produtor_uuid', type: 'select', values: produtores },
            { label: 'Propriedade', name: 'propriedade' },
            { label: 'Latitude', name: 'latitude' },
            { label: 'Longitude', name: 'longitude' },
            { label: 'Matrícula', name: 'matricula' },
            { label: 'Classificacao', name: 'classificacao_pqfl' },
        ]}
        inicialValues={{
            uuid: v4(),
            produtor_uuid: '',
            propriedade: '',
            latitude: '',
            longitude: '',
            matricula: '',
            classificacao_pqfl: '',
        }}
        resource='propriedades'
    />
};
