import React from 'react';
import { Card as CardStrap} from 'reactstrap';

const Card = props => {
    return (
        <CardStrap className="animated fadeIn">
            {props.children}
        </CardStrap>
    )
};
export default Card