import axios from "axios";
import { buildUrlParams } from "./Utils";

const API_URL =
  process.env.NODE_ENV === "production"
    ? "https://autocontrole.app.br/agro"
    : "http://192.168.18.29:80";
export { API_URL };

export const Api = {
  login: async (data) => {
    try {
      const request = await axios.post("/login", data);
      return [request.data, null];
    } catch (error) {
      return [null, error];
    }
  },

  getNatigation: async () => {
    try {
      const resp = await axios.get("/navigation");
      return [resp.data, null];
    } catch (error) {
      return [null, error];
    }
  },

  contagemMensal: async () => {
    try {
      const response = await axios.get("/contagemDiaria");
      return [response.data, null];
    } catch (error) {
      return [null, error];
    }
  },

  atualizarColaborador: async (uuid, values) => {
    const response = await axios.patch("v2/colaboradores/" + uuid, values);
    return response;
  },
  adiconarColaborador: async (colaborador) => {
    const response = await axios.post("v2/colaboradores", colaborador);
    return response.data;
  },
  fetchColaboradores: async () => {
    const responseColaboradores = await axios.get("v2/colaboradores");
    return responseColaboradores.data;
  },
  fetchColaborador: async (uuid) => {
    try {
      const responseColaboradores = await axios.get("v2/colaboradores/" + uuid);
      return [responseColaboradores.data, null];
    } catch (error) {
      return [null, error];
    }
  },
  adicionarFormulario: async (form) => {
    try {
      const response = await axios.post("esqueletos", form);
      return [response, null];
    } catch (error) {
      return [null, error];
    }
  },

  fetchEsqueletos: async () => {
    const responseEsqueletos = await axios.get("esqueletos?serializar=1");
    return responseEsqueletos.data;
  },
  fetchEsqueleto: async (uuid) => {
    try {
      const response = await axios.get("esqueletos/" + uuid + "?serializar=1");

      return [response.data, null];
    } catch (error) {
      return [null, error];
    }
  },
  atualizarEsqueleto: async (esqueletoAtualizado) => {
    try {
      const response = await axios.patch(
        `esqueletos/${esqueletoAtualizado.uuid}`,
        esqueletoAtualizado
      );
      return [response, null];
    } catch (error) {
      return [null, error];
    }
  },

  fetchPreenchidos: async (esqueleto_uuid = null) => {
    try {
      let url = "preenchidos";

      if (esqueleto_uuid) {
        url += "?esqueleto_uuid=" + esqueleto_uuid;
      }
      const responseCabecalho = await axios.get(url);

      const preenchidoHeaders = responseCabecalho.data.map((data) => {
        return {
          ...data,
          localizacao: data.localizacao ? JSON.parse(data.localizacao) : {},
          nao_conformidades: data.nao_conformidades
            ? JSON.parse(data.nao_conformidades)
            : {},
          respostas: data.respostas ? JSON.parse(data.respostas) : {},
          acoes_corretivas: data.acoes_corretivas
            ? JSON.parse(data.acoes_corretivas)
            : {},
        };
      });
      return [preenchidoHeaders, null];
    } catch (error) {
      return [null, error];
    }
  },

  fetchPreenchido: async (uuid) => {
    try {
      const response = await axios.get("preenchidos/" + uuid);
      const preenchido = {
        ...response.data,
        localizacao: response.data.localizacao
          ? JSON.parse(response.data.localizacao)
          : {},
        nao_conformidades: response.data.nao_conformidades
          ? JSON.parse(response.data.nao_conformidades)
          : {},
        respostas: response.data.respostas
          ? JSON.parse(response.data.respostas)
          : {},
        acoes_corretivas: response.data.acoes_corretivas
          ? JSON.parse(response.data.acoes_corretivas)
          : {},
      };
      return [preenchido, null];
    } catch (error) {
      return [null, error];
    }
  },
  fetchNaoConformidades: async (_) => {
    try {
      const resposta = await axios.get(
        "/novasNaoConformidades?status=AGUARDANDO_REVISAO"
      );
      return [resposta.data, null];
    } catch (error) {
      return [null, error];
    }
  },
  aprovarNaoConformidade: async (values) => {
    try {
      const resposta = await axios.patch(
        `/novasNaoConformidades/${values.uuid}/aprovar `,
        values
      );
      return [resposta.data, null];
    } catch (error) {
      return [null, error];
    }
  },
  reprovarNaoConformidade: async (uuid) => {
    try {
      const resposta = await axios.patch(
        `/novasNaoConformidades/${uuid}/reprovar`
      );
      return [resposta.data, null];
    } catch (error) {
      return [null, error];
    }
  },
  fetchGrupos: async () => {
    const response = await axios.get("/grupos");
    return response.data;
  },
  fetchGrupo: async (uuid) => {
    try {
      const response = await axios.get("grupos/" + uuid);
      return [response.data, null];
    } catch (error) {
      return [null, error];
    }
  },
  adicionarGrupo: async (grupo) => {
    try {
      const response = await axios.post("grupos", grupo);
      return [response, null];
    } catch (error) {
      return [null, error];
    }
  },
  atualizarGrupo: async (grupo) => {
    try {
      const response = await axios.patch("grupos/" + grupo.uuid, grupo);
      return [response, null];
    } catch (error) {
      return [null, error];
    }
  },

  fetchDispositivoHistorico: async (uuid) => {
    const response = await axios.get(`/dispositivos/${uuid}/historico`);
    return response.data;
  },
  fetchDispositivos: async () => {
    try {
      const response = await axios.get(`/dispositivos`);
      return [response.data, null];
    } catch (error) {
      return [null, error];
    }
  },
  fetchDispositivo: async (uuid) => {
    const response = await axios.get(`/dispositivos/${uuid}`);
    return response.data;
  },
  adicionarDispositivo: async (dados) => {
    try {
      const response = await axios.post(`/dispositivos`, dados);
      return [response.data, null];
    } catch (error) {
      return [null, error];
    }
  },
  atualizarDispositivo: async (dados) => {
    try {
      const response = await axios.patch(`/dispositivos/${dados.uuid}`, dados);
      return [response.data, null];
    } catch (error) {
      return [null, error];
    }
  },
  fetchObservacoes: async (preenchido_uuid) => {
    try {
      const response = await axios.get(
        `/preenchidos/${preenchido_uuid}/observacoes`
      );
      return [response.data, null];
    } catch (error) {
      return [null, error];
    }
  },
  fetchMonitoramentos: async (_) => {
    try {
      const response = await axios.get(
        `/grupos/esqueletos/preenchidos/totalizacao`
      );
      return [response.data, null];
    } catch (error) {
      return [null, error];
    }
  },
  fetchPreenchidoEmPDF: async (preenchido) => {
    try {
      const response = await axios.get("/checklist01/" + preenchido.uuid, {
        responseType: "blob",
      });
      return [response.data, null];
    } catch (error) {
      return [null, error];
    }
  },
  fetchSetores: async ({ orderBy }) => {
    let url = "/setores?";
    if (orderBy) {
      url += "orderBy=" + orderBy;
    }
    const response = await axios.get(url);
    return response.data;
  },
  fetchSetor: async (uuid) => {
    try {
      const response = await axios.get(`/setores/${uuid}`);
      return [response.data, null];
    } catch (error) {
      return [null, error];
    }
  },
  adicionarSetor: async (setor) => {
    try {
      const response = await axios.post(`/setores`, setor);
      return [response.data, null];
    } catch (error) {
      return [null, error];
    }
  },
  atualizarSetor: async (setor) => {
    try {
      const response = await axios.patch(`/setores/${setor.uuid}`, setor);
      return [response.data, null];
    } catch (error) {
      return [null, error];
    }
  },
  fetchPermissoes: async (_) => {
    try {
      const response = await axios.get(`/permissoes`);
      return [response.data, null];
    } catch (error) {
      return [null, error];
    }
  },
  setPermissoes: async (permissoes, colaboradorUuid) => {
    try {
      const response = await axios.patch(
        `/v2/colaboradores/${colaboradorUuid}/permissoes`,
        permissoes
      );
      return [response.data, null];
    } catch (error) {
      return [null, error];
    }
  },
  fetchFuncionarios: async (_) => {
    try {
      const response = await axios.get("/funcionarios");
      return [response.data, null];
    } catch (error) {
      return [null, error];
    }
  },
  GerarQRCodes: async (entidade, uuids) => {
    try {
      const response = await axios.get(
        `/qrcode/${entidade}?uuids=${uuids.join(",")}`,
        { responseType: "blob" }
      );
      return [response.data, null];
    } catch (error) {
      return [null, error];
    }
  },
  fetchAgendas: async (_) => {
    const response = await axios.get(`/agendas`);
    return response.data;
  },  
  fetchAgenda: async (uuid) => {
    const response = await axios.get(`/agendas/${uuid}`);
    return response.data;
  },
  atualizarAgenda: async (uuid, values) => {
    const response = await axios.patch(`/agendas/${uuid}`, values);
    return response.data;
  },
  adicionarAgenda: async (values) => {
    const response = await axios.post(`/agendas`, values);
    return response.data;
  },
  fetchArea: async (areaUuid) => {
    const response = await axios.get(`/areas/${areaUuid}`);
    return response.data;
  },
  atualizarArea: async (uuid, values) => {
    const response = await axios.patch(`/areas/${uuid}`, values);
    return response.data;
  },
  adicionarArea: async (values) => {
    const response = await axios.post(`/areas`, values);
    return response.data;
  },
  fetchEquipamento: async (uuid) => {
    const response = await axios.get(`/equipamentos/${uuid}`);
    return response.data;
  },
  fetchDinamicoTabelas: async (_) => {
    const response = await axios.get(`/dinamico/tabelas`);
    return response.data;
  },
  adicionarTabelaDinamica: async (data) => {
    const response = await axios.post(`/dinamico/tabelas`, data);
    return response.data;
  },
  fetchDinamicoTabela: async (uuid) => {
    const response = await axios.get(`/dinamico/tabelas/${uuid}`);
    return response.data;
  },
  atualizarTabelaDinamica: async (uuid, values) => {
    const response = await axios.patch(`/dinamico/tabelas/${uuid}`, values);
    return response.data;
  },

  fetchDinamicoRegistros: async (filtros) => {
    let url = `/dinamico/registros?`;

    url += buildUrlParams(filtros);

    const response = await axios.get(url);

    return response.data;
  },

  adicionarRegistroDinamico: async (values) => {
    const response = await axios.post(`/dinamico/registros`, values);
    return response.data;
  },

  atualizarRegistroDinamico: async (uuid, values) => {
    const response = await axios.patch(`/dinamico/registros/${uuid}`, values);
    return response.data;
  },
  fetchDinamicoRegistro: async (uuid) => {
    const response = await axios.get(`/dinamico/registros/${uuid}`);
    return response.data;
  },
};
export default Api;