import { gql, useQuery } from '@apollo/client';
import moment from 'moment';
import React from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import LinkEditar from '../../../components/ui/LinkEditar';
import LinkNovo from '../../../components/Templates/LinkNovo';
import { Table, Thead } from '../../../components/ui/Table';
import { Loading } from '../../../lib';
import { formatDate } from '../../../lib/DateUtil';



export const Treinamentos = props => {

    const query = gql`
    query GetTreinamentos{
        treinamentos(first:15, page:1){
            data{
                    uuid,
                    descricao,
                    duracao, 
                    validade
            }
        }
    }
    `

    const { loading, data, error, refetch } = useQuery(query)

    useEffect(() => {
        if (!loading) {
            refetch();
        }
    }, [loading]);
    if (loading) return <Loading />


    if (error) {
        toast.error(error.message);
        return null;
    }

    return (
        <>
            <Card>
                <CardHeader>
                    <h2 className='d-inline font-weight-light'>Treinamentos</h2>
                    <div className="card-header-actions">
                        <LinkNovo to={'/treinamentos/novo'} />
                    </div>
                </CardHeader>
                <CardBody>
                    <Table>
                        <Thead>
                            <tr>
                                <th>Descrição</th>
                                <th>Duração</th>
                                <th>Validade</th>
                                <th>Ações</th>
                            </tr>
                        </Thead>
                        <tbody>
                            {data.treinamentos.data.map(t => (
                                <tr>
                                    <td>{t.descricao}</td>
                                    <td>{t.duracao}</td>
                                    <td>{t.validade}</td>
                                    <td>
                                        <LinkEditar to={`treinamentos/editar/${t.uuid}`} />
                                    </td>
                                </tr>

                            ))}
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
        </>
    )
};