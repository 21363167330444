import React, { memo, useState } from "react";
import { useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Button,
  FormGroup,
  Label,
  Input,
  CardFooter,
  Collapse,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Tooltip,
  Badge,
} from "reactstrap";

import "./styles.css";
import {
  NumberInput,
  ParagraphInput,
  ImageInput,
  CheckboxInput,
  Signature,
  DateTimeInput,
  VinculoInput,
  TextInput,
  SwitchInput,
  DateInput,
  HeaderInput,
  OptionsInput,
} from "../Components";

import { perguntasDescricao } from "../../../constantes";
import PossiveisNaoConformidades from "../common/PossiveisNaoConformidades";
import PossiveisAcoesCorretivas from "../common/PossiveisAcoesCorretivas";
import OptionsTab from "./OptionsTab";
const FormInput = ({
  index,
  field,
  readOnly,
  perguntaChangeHandler,
  deleteHandler,
  upClickHandler,
  downClickHandler,
  inicialToggleState,
  typeChangeHandler,
}) => {
  const changeHandler = (values) => {
    const updated = { ...field, ...values };
    perguntaChangeHandler(updated);
  };

  useEffect(() => {
    window.scrollBy({
      top: window.innerHeight,
      behavior: "smooth",
    });
  }, []);

  const tabs = [
    { text: "Principal", value: "1" },
    {
      text: () => {
        return (
          <>
            {"Opções "}
            <Badge>{field.values?.length ?? "0"}</Badge>
          </>
        );
      },
      value: "2",
    },
    {
      text: () => {
        return (
          <>
            {"Não Conformidades "}
            <Badge>{field.possiveis_nao_conformidades?.length ?? "0"}</Badge>
          </>
        );
      },
      value: "3",
    },
    {
      text: () => {
        return (
          <>
            {"Ações Corretivas "}
            <Badge>{field.possiveis_acoes_corretivas?.length ?? "0"}</Badge>
          </>
        );
      },
      value: "4",
    },
  ];

  const typesTabs = {
    text: ["1"],
    "radio-group": ["1", "2", "3", "4"],
    select: ["1", "2", "3", "4"],
    date: ["1"],
    "date-time": ["1"],
    header: ["1"],
    paragraph: ["1"],
    number: ["1"],
    switch: ["1", "3", "4"],
    checkbox: ["1", "3", "4"],
    file: ["1"],
    signature: ["1"],
    vinculo: ["1"],
  };

  const types = {
    text: <TextInput field={field} onChange={changeHandler} />,
    date: <DateInput field={field} onChange={changeHandler} />,
    select: <OptionsInput field={field} onChange={changeHandler} select />,
    "radio-group": <OptionsInput field={field} onChange={changeHandler} />,
    "date-time": <DateTimeInput field={field} onChange={changeHandler} />,
    header: (
      <HeaderInput
        field={field}
        label={field.label}
        subtype={field.subtype}
        onChange={changeHandler}
      />
    ),
    paragraph: <ParagraphInput label={field.label} />,
    number: <NumberInput field={field} onChange={changeHandler} />,
    switch: <SwitchInput field={field} onChange={changeHandler} />,
    checkbox: <CheckboxInput field={field} onChange={changeHandler} />,
    file: <ImageInput field={field} onChange={changeHandler} />,
    signature: <Signature field={field} onChange={changeHandler} />,
    vinculo: <VinculoInput field={field} onChange={changeHandler} />,
    multifile: <ImageInput field={field} onChange={changeHandler} />,
  };

  const [isFocused, setIsFocused] = useState(inicialToggleState);
  const toggle = () => setIsFocused((state) => !state);
  const [activeTab, setActiveTab] = useState("1");

  return (
    <Card
      className={`animated fadeIn ${isFocused ? "shadow" : ""}`}
      title="pergunta"
    >
      <CardHeader className="bg-gray-100 ">
        <div className="d-flex justify-content-between align-items-center">
          <div
            className="flex-fill"
            onClick={toggle}
            style={{ cursor: "pointer" }}
          >
            <i
              className={`cui-chevron-${isFocused ? "top" : "bottom"} icons`}
            ></i>
            <strong className="link">
              {" "}
              Pergunta {index + 1} : {field.label ? field.label : ""}{" "}
            </strong>
            <div className="text-muted font-sm font-weight-light">
              {" "}
              {perguntasDescricao[field.type]}
            </div>
          </div>
          <div>
            <Button
              color="link"
              className="p-0 mr-2"
              onClick={upClickHandler.bind(this, field.id)}
            >
              <i className="cui-arrow-top icons  font-xl"></i>
            </Button>
            <Button
              color="link"
              className="p-0"
              onClick={downClickHandler.bind(this, field.id)}
            >
              <i className="cui-arrow-bottom icons font-xl"></i>
            </Button>
          </div>
        </div>
      </CardHeader>
      <Collapse isOpen={isFocused}>
        <CardBody className="animated fadeIn">
          <Nav tabs>
            {tabs.map((tab) => (
              <NavItem key={tab.value}>  
                <NavLink
                  disabled={!typesTabs[field.type]?.includes(tab.value)}
                  active={activeTab === tab.value}
                  onClick={() => {
                    setActiveTab(tab.value);
                  }}
                >
                  {typeof tab.text === "function" ? tab.text() : tab.text}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <FormGroup>
                <Label>Tipo de dado:</Label>
                <Input
                  type="select"
                  readOnly={readOnly}
                  onChange={(t) => typeChangeHandler(field, t.target.value)}
                  value={field.type}
                >
                  <optgroup label="Apresentação">
                    <option value="header">Título</option>
                    <option value="paragraph">Parágrafo</option>
                  </optgroup>

                  <optgroup label="Comum">
                    <option value="text">Texto</option>
                    <option value="number">Numérico</option>
                    <option value="switch">Conforme / Não Conforme</option>
                    <option value="checkbox">Caixa de Seleção</option>
                    <option value="date">Data</option>
                    <option value="date-time">Data Hora</option>
                    <option value="file">Foto</option>
                    <option value="multifile">Múltiplas Fotos</option>
                    <option value="signature">Assinatura</option>
                  </optgroup>

                  <optgroup label="Lista">
                    <option value="vinculo">Vínculo</option>
                    <option value="radio-group">Multipla Escolha</option>
                    <option value="select">Multipla Escolha Suspensa</option>
                  </optgroup>
                </Input>
              </FormGroup>
              <FormGroup>
                <Label>Perguta:</Label>
                <Input
                  readOnly={readOnly}
                  value={field.label ? field.label : ""}
                  placeholder="Nova pergunta sem título"
                  onChange={(t) => {
                    changeHandler({ label: t.target.value });
                  }}
                />
              </FormGroup>

              {types[field.type]}
            </TabPane>
            <TabPane tabId="2">
              <OptionsTab field={field} onChange={changeHandler} />
            </TabPane>
            <TabPane tabId="3">
              <PossiveisNaoConformidades
                field={field}
                onChange={changeHandler}
              />
            </TabPane>
            <TabPane tabId="4">
              <PossiveisAcoesCorretivas
                field={field}
                onChange={changeHandler}
              />
            </TabPane>
          </TabContent>

          {!readOnly && (
            <CardFooter className="bg-white border-top-0 text-right">
              <Button
                onClick={() => deleteHandler(field.id)}
                type="button"
                size="sm"
                color="danger"
              >
                <i className="fa fa-ban"></i> Deletar
              </Button>
            </CardFooter>
          )}
        </CardBody>
      </Collapse>
    </Card>
  );
};

export default memo(FormInput);
