import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { ModalPreenchidos,TableTeamplate } from '../../../components/Templates';
export const Produtores = props => {
    const headers = [
        {
            label: 'Nome',
            filtravel: true,
            name: 'nome'
        },
        {
            label: 'Fantasia',
            filtravel: true,
            name: 'fantasia'
        },
        {
            label: 'Documento',
            filtravel: true,
            name: 'cnpj_cpf'
        },
        {
            label: 'I.E',
            filtravel: true,
            name: 'ie'
        },
        {
            label: 'Email',
            filtravel: true,
            name: 'email'
        },
        {
            label: 'Matrícula',
            filtravel: true,
            name: 'matricula'
        },
        {
            label: 'Ações',
        },
    ];
    const body = [
        'nome',
        'fantasia',
        'cnpj_cpf',
        'ie',
        'email',
        'matricula',
        (cadastro) =>
            <div className='d-flex align-items-center'>
                <Link to={`/produtores/editar/${cadastro.uuid}`}>
                    <i className="cui-note icons font-2xl "></i>
                </Link>
                <Button color="link" className="pl-2 py-0" onClick={() => modalToggle(cadastro.id)}>
                    <i className="cui-layers icons font-2xl"></i>
                </Button>
            </div>
    ]

    const [modal, setModal] = useState();
    const [produtor, setProdutor] = useState();

    const modalToggle = (id) => {
        setProdutor(id);
        setModal(p => !p);
    }

    return (
        <>
            <TableTeamplate
                titulo='Produtores'
                headers={headers}
                rota={'produtores'}
                body={body}
                end_point={'/produtores'} />
            {produtor ? <ModalPreenchidos
                filtros={{ produtorId: produtor }}
                isOpen={modal}
                toggle={() => modalToggle()} /> : null}
        </>
    )
};
