// import 'react-app-polyfill/ie9'; // For IE 9-11 support
import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import "./polyfill";
import React from 'react';
import ReactDOM from 'react-dom';
import { App } from "./App";
import { ApolloProvider } from "@apollo/client";
import { Provider } from "react-redux";
import { persistor, store } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import { GraphqlCliente } from "./graphql/client";

const container = document.getElementById("root");
ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ApolloProvider client={GraphqlCliente}>
        <App />
      </ApolloProvider>
    </PersistGate>
  </Provider>,
  container
);


