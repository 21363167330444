import React from 'react';
import { useState } from 'react';
const useToggle = (inicialState) => {
    const [state, setState] = useState(inicialState)

    const toggle = () => {
        setState(p => !p);
    }

    return { state, toggle }

};
export default useToggle