import Axios from "axios";
import Api from "../../lib/Api";

export const LOAD_NAVIGATION = 'LOAD_NAVIGATION';


export const loadNavigation = () => {

    return async (dispatch) => {

        const [data, error] = await Api.getNatigation();

        if(!error){
            dispatch({ type: LOAD_NAVIGATION, navigation: data });
        }
    }
};
